import { UploadFile, UploadProps } from 'antd/es/upload';
import { Button, Modal, Upload } from 'antd'
import { BiUpload } from 'react-icons/bi'
import { useState } from 'react';
import { toast } from 'sonner';

import { useUiContext } from '../../context/ui';
import { sendInvoice } from '../../api/invoice';

export const InvoiceModal = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const { showInvoiceModal, setSetshowInvoiceModal } = useUiContext()
  const { uniqueID, email, _id } = showInvoiceModal.order! || {}

  const handleCancel = () => {
    setSetshowInvoiceModal({ visible: false, order: null });
  };

  const handleOk = () => {
    setUploading(true);
    sendInvoice({ uniqueID, email, files: fileList })
      .then((response) => {
        setFileList([]);
        handleCancel()
        toast.success('Correo enviado con exito!.');
      })
      .catch((error) => {
        toast.error('Error al enviar el correo.');
        console.log(error);
      })
      .finally(() => setUploading(false))
  };

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };

  return (
    <Modal
      title="Enviar un correo"
      confirmLoading={uploading}
      open={showInvoiceModal.visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText='Enviar'
      cancelText='Cancelar'
    >
      <Upload  {...props}>
        <Button
          style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
          icon={<BiUpload size={18} />}
        >
          Subir Archivo
        </Button>
      </Upload>
    </Modal>
  )
}
