import { FormInstance, Select, Tag } from "antd";
import { useState } from "react";

import { FLEETS } from "../constants/fleets";

export const CustomSelect = ({ form }: { form: FormInstance<any> }) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(form.getFieldValue('fleet'));

  const onTagClose = () => {
    setSelectedValue(undefined);
  };

  const tagRender = (props: any) => {
    const { label, value, closable } = props;
    const isCurrentValue = selectedValue === value;

    return (
      <Tag color={value} closable={closable && isCurrentValue} onClose={onTagClose}>
        {label}
      </Tag>
    );
  };

  const onSelect = (value: any) => {
    setSelectedValue(value);
    form.setFieldValue('fleet', value)
  };

  return (
    <Select
      optionRender={tagRender}
      options={FLEETS}
      value={selectedValue}
      onSelect={onSelect}
    />
  );
}