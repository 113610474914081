import { Button, Dropdown, Input, MenuProps, Select, Table, Tag } from "antd";
import { BiCheckCircle, BiPencil, BiTrash, BiXCircle } from "react-icons/bi";
import { RiUserSettingsLine } from "react-icons/ri";
import { useSearchParams } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import { differenceInDays } from 'date-fns';

import { IUser } from "../../../interfaces/user";
import { useUiContext } from "../../../context/ui";
import { useUsersContext } from "../../../context/users";
import { DeletUserModal } from "../../../components/modals/delet-user-modal";
import { UpdateUser } from "../../../components/modals/update-user";

const { Search } = Input;

export const UsersScreen = () => {
  const { total, page, limit, users, loading, handleFilterChange } = useUsersContext();
  const { setShowDeletuser, setShowUpdateUser } = useUiContext();
  const [searchParams] = useSearchParams();

  const getMenuItems = (record: IUser | undefined): MenuProps["items"] => [
    {
      key: "1",
      icon: <BiPencil size={14} />,
      label: "Modificar",
      onClick: () => setShowUpdateUser({ visible: true, user: record! }),
    },
    {
      key: "5",
      icon: <BiTrash size={14} />,
      label: "Eliminar",
      onClick: () => setShowDeletuser({ visible: true, user: record! }),
    },
  ];

  const columns: ColumnsType<any> = [
    {
      title: "Nombre de Usuario",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Correo",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Antiguedad",
      dataIndex: "createdAt",
      key: "days_as_user",
      render: (createdAt) => {
        const days = differenceInDays(new Date(), new Date(createdAt));
        return `${days} ${days === 1 ? 'día' : 'días'}`;
      },
    },
    {
      title: "Rol",
      dataIndex: "role",
      key: "role",
      render: (role) => {
        let text = role === "admin" ? "Administrador" : "Cliente";
        let color = text === "Administrador" ? "green" : "cyan";
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: "Puntos",
      dataIndex: "score",
      key: "score",
    },
    {
      align: "center",
      title: "Telefono",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Verificado",
      dataIndex: "is_active",
      key: "is_active",
      render: (is_active) => (
        <Tag
          className="inline-flex items-center gap-x-1"
          icon={is_active ? <BiCheckCircle /> : <BiXCircle />}
          color={is_active ? "green" : "red"}
        >
          {is_active ? "Verificado" : "Sin verificar"}
        </Tag>
      ),
    },
    {
      title: "Acciones",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <Dropdown
          menu={{ items: getMenuItems(record) }}
          placement="bottomRight"
          trigger={["click"]}
        >
          <Button>Acciones</Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="flex-1 space-y-4 p-4 md:p-8 pt-6">
      <div className="flex items-center justify-start space-x-3">
        <RiUserSettingsLine size={24} />
        <h1 className="font-semibold text-2xl">Usuarios</h1>
      </div>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4 py-2">
        <Search
          placeholder="Buscar..."
          onSearch={(value) => handleFilterChange([["term", value]])}
          defaultValue={searchParams.get("term") || undefined}
          allowClear
        />
        <Select
          allowClear
          placeholder="Roles de Usuarios"
          onChange={(value) => handleFilterChange([["role", value], ["page", "1"]])}
          defaultValue={searchParams.get("role") || undefined}
          options={[
            {
              label: "Administrador",
              value: "admin",
            },
            {
              label: "Cliente",
              value: "client",
            },
          ]}
        />
        <Select
          allowClear
          placeholder="Antiguedad de Usuarios"
          onChange={(value) => {
            handleFilterChange([["sort", value], ["orderBy", 'createdAt']])
          }}
          defaultValue={searchParams.get("createdAt") || undefined}
          options={[
            {
              label: "Mas Antiguo",
              value: "asc",
            },
            {
              label: "Menos Antiguo",
              value: "desc",
            },
          ]}
        />
        <Select
          allowClear
          placeholder="Puntuación"
          onChange={(value) => {
            handleFilterChange([["sort", value], ["orderBy", 'score']])
          }}
          defaultValue={searchParams.get("score") || undefined}
          options={[
            {
              label: "Mas Puntuacion",
              value: "desc",
            },
            {
              label: "Menos Puntuacion",
              value: "asc",
            },
          ]}
        />
      </div>
      <Table
        scroll={{ x: 1000 }}
        loading={loading}
        columns={columns}
        dataSource={users}
        pagination={{
          locale: {
            items_per_page: 'x pág.',
          },
          total,
          current: page,
          pageSize: limit,
          onChange: (page, pageSize) => handleFilterChange([["page", page.toString()], ["limit", pageSize.toString()]]),
          showSizeChanger: true
        }}
        key="_id"
      />
      <DeletUserModal />
      <UpdateUser />
    </div>
  );
};
