import { createContext, useContext, useEffect, useState } from 'react';

import { IProduct } from '../interfaces/product';
import { Pagination, getProducts } from '../api/product';


interface UiProviderProps {
  children: JSX.Element | JSX.Element[]
}

interface UiContextProps {
  loading: boolean
  products: IProduct[]
  limit: number
  total: number
  page: number
  handleSearch: (term: string) => void
  onPageChange: (page: number) => void
  fetchProducts: (query: Pagination) => void
  handleCategoryChange: (category: string) => void
  onPageSizeChange: (current: number, size: number) => void
}

const Context = createContext<UiContextProps | undefined>(undefined)

export const useProductContext = (): UiContextProps => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('Order context debe ser utilizado dentro de un provider.');
  }
  return context;
};

export const ProductProvider = ({ children }: UiProviderProps) => {
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState([])
  const [limit, setLimit] = useState(10)
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState<Pagination>({ limit, page, category: undefined })

  const fetchProducts = (query: Pagination) => {
    setLoading(true)
    getProducts(query)
      .then(({ data }) => {
        console.log(data)
        setProducts(data.results)
        setLimit(data.metadata.limit)
        setTotal(data.metadata.total)
        setPage(data.metadata.skip)
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false))
  }
  const onPageChange = (page: number) => {
    setPage(page);
    setQuery((prevQuery) => ({ ...prevQuery, page }));
  };

  const onPageSizeChange = (current: any, size: any) => {
    setLimit(size);
    setQuery((prevQuery) => ({ ...prevQuery, limit: size }));
  };

  const handleSearch = (term: string) => {
    setQuery((prevQuery) => ({ ...prevQuery, term, page: 1 }));
  };

  const handleCategoryChange = (category: string) => {
    setQuery((prevQuery) => ({ ...prevQuery, category, page: 1 }));
  };

  useEffect(() => {
    fetchProducts(query)
  }, [query])

  const orderContextValue: UiContextProps = {
    products,
    loading,
    limit,
    total,
    page,
    onPageChange,
    fetchProducts,
    onPageSizeChange,
    handleSearch,
    handleCategoryChange
  };

  return (
    <Context.Provider value={orderContextValue}>
      {children}
    </Context.Provider>
  )
}