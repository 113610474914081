import { Layout } from "antd";
import { Toaster } from "sonner";
import { TourProvider } from '@reactour/tour';

import { Header } from "./main-header";
import { RootProvider } from "../../context";
import { OrderModal } from "../modals/order-modal";
import { MobileSidebar } from "./mobile-sidebar";
import { useTheme } from "../../context/theme";
import { OptionsModal } from "../modals/options-modal";
import { InvoiceModal } from "../modals/invoice-modal";
import TourSteps from '../../constants/tour-steps';

interface MainLayoutProps {
  children: JSX.Element | JSX.Element[];
}

const { Content, Footer } = Layout;

export const MainLayout = ({ children }: MainLayoutProps) => {
  const { theme } = useTheme();

  return (
    <RootProvider>
      <TourProvider steps={TourSteps} className="tour-popover" maskClassName="tour-mask">
        <Header />
        <MobileSidebar />
        <div className="flex flex-1 flex-col min-h-screen w-full">
          <Content className="w-full pt-12 flex-1 overflow-hidden">
            {children}
          </Content>
          <Footer className="text-center bg-transparent border-t">
          Versión Alpha 2.0.0 - Cuatro Carnes ®
          </Footer>
        </div>
        <OrderModal />
        <OptionsModal />
        <InvoiceModal />
        <Toaster theme={theme} />
      </TourProvider>
    </RootProvider>
  );
};
