import { Button, Card, Col, Input, Row, Select, Space } from "antd";

import { ProductTable } from "../../../components/tables/product/product-table";
import { ProductDrawer } from "../../../components/drawer/product-drawer";
import { useProductContext } from "../../../context/product";
import { useUiContext } from "../../../context/ui";
import { DeleteProductModal } from "../../../components/modals/delete-product";
import { useCategoriesContext } from "../../../context/categories";
import { BsBoxSeam } from "react-icons/bs";

const { Search } = Input;

export const ProductDetails = () => {
  const { categories } = useCategoriesContext()
  const { products, limit, loading, onPageChange, onPageSizeChange, handleCategoryChange, handleSearch, page, total } = useProductContext()
  const { setShowProductDrawer } = useUiContext()

  return (
    <div className="flex-1 space-y-4 p-4 md:p-8 pt-6">
      <div className="flex items-center justify-start space-x-3">
        <BsBoxSeam size={24} />
        <h1 className="font-semibold text-2xl">Productos</h1>
      </div>
      <Card>
        <Row gutter={[12, 12]}>
          <Col xs={24} md={4} lg={6}>
            <Search placeholder="Buscar..." onSearch={(value) => handleSearch(value)} />
          </Col>
          <Col xs={24} md={6} lg={6}>
            <Select
              onChange={(value) => handleCategoryChange(value)}
              placeholder='Seleccione una categoría'
              style={{ width: '100%' }}
              allowClear
              options={
                categories.map(({ name }: { name: string }) => (
                  { value: name, label: name, key: name }
                ))
              }
            />
          </Col>
          <Col flex={1}>
          </Col>
          <Col>
            <Space wrap>
              <Button onClick={() => setShowProductDrawer({ visible: true, product: undefined })}>Crear Producto</Button>
            </Space>
          </Col>
        </Row>
      </Card>
      <ProductTable
        data={products}
        pageSize={limit}
        isLoading={loading}
        currentPage={page}
        onPageChange={onPageChange}
        pages={Math.ceil(total / limit)}
        onPageSizeChange={onPageSizeChange}
      />
      <ProductDrawer />
      <DeleteProductModal />
    </div>
  )
}
