import { Card, Button, Table, Tag, Dropdown, Row, Col, Space, Modal } from "antd";
import { AiOutlineLock, AiOutlineUnlock } from "react-icons/ai";
import { MdOutlineComputer } from "react-icons/md";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { BiPencil } from "react-icons/bi";
import { toast } from "sonner";

import { CouponDrawer } from "../../../components/drawer/coupon-drawer";
import { activateCoupon, deleteCoupon } from "../../../api/coupons";
import { useUiContext } from "../../../context/ui";
import instance from "../../../api";
import { BsTicketPerforated } from "react-icons/bs";

export const CouponScreen = () => {
  const { setShowCuponModal } = useUiContext();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [showDeletModal, setShowDeletModal] = useState({ visible: false, id: null });

  const handleActivate = (id: string, isActive: boolean) => {
    const action = isActive ? "activar" : "desactivar";
    activateCoupon(id, {
      is_active: isActive,
    })
      .then(() => {
        toast.success(`Cupón ${action} con éxito!`);
        fetchCoupons();
      })
      .catch((error) => {
        console.error(error);
        toast.error(`Oops! Algo salió mal al ${action} el cupón.`);
      });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Precio",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Descuento (%)",
      dataIndex: "discount",
      key: "discount",
      render: (value) => `%${value}`,
    },
    {
      title: "Descripción",
      dataIndex: "description",
      key: "description",
    },
    {
      align: "center",
      title: "Estado",
      dataIndex: "is_active",
      key: "is_active",
      render: (status) => {
        const text = status ? "Activo" : "Inactivo";
        const color = status ? "green" : "default";
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: "Valor de Descuento",
      dataIndex: "discount_value",
      key: "discount_value",
    },
    {
      title: "Tipo de Descuento",
      dataIndex: "discount_type",
      key: "discount_type",
      render: (type) => {
        switch (type) {
          case "PERCENT":
            return "Porcentual";
          case "FIXED":
            return "Fijo";
        }
      },
    },
    {
      width: 180,
      title: "Acciones",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <Dropdown
          menu={{
            items: [
              {
                key: "1",
                icon: <BiPencil size={14} />,
                label: "Modificar",
                onClick: () =>
                  setShowCuponModal({ visible: true, id: record._id! }),
              },
              {
                key: "4",
                icon: record?.is_active ? (
                  <AiOutlineLock size={14} />
                ) : (
                  <AiOutlineUnlock size={14} />
                ),
                label: record?.is_active ? "Desactivar" : "Activar",
                onClick: () => handleActivate(record?._id!, !record?.is_active),
              },
              {
                key: "5",
                icon: <MdOutlineComputer size={14} />,
                label: "Eliminar",
                onClick: () => setShowDeletModal({ visible: true, id: record?._id! }),
              },
            ],
          }}
          placement="bottomRight"
          trigger={["click"]}
        >
          <Button>Acciones</Button>
        </Dropdown>
      ),
    },
  ];

  const fetchCoupons = () => {
    setIsLoading(true);
    instance
      .get("/coupons")
      .then((res) => setData(res.data))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchCoupons();
  }, []);

  return (
    <div className="flex-1 space-y-4 p-4 md:p-8 pt-6">
      <div className="flex items-center justify-start space-x-3">
        <BsTicketPerforated size={24} />
        <h1 className="font-semibold text-2xl">Cupones</h1>
      </div>
      <Card>
        <Row gutter={[0, 12]}>
          <Col xs={24} md={6} lg={6}>
            <span className="mr-2">•</span>Crea y gestiona cupones de descuento porcentual o fijo
          </Col>
          <Col flex={1}></Col>
          <Col>
            <Space wrap>
              <Button
                onClick={() =>
                  setShowCuponModal({ id: null, visible: true })
                }
              >
                Crear Cupón
              </Button>
            </Space>
          </Col>
        </Row>
      </Card>
      <Table
        scroll={{ x: 1000 }}
        loading={isLoading}
        columns={columns}
        dataSource={data}
      />
      <CouponDrawer fetchCoupons={fetchCoupons} />
      <DeletedCouponModal
        showDeletModal={showDeletModal}
        setShowDeletModal={setShowDeletModal}
        fetchCoupons={fetchCoupons}
      />
    </div>
  );
};


const DeletedCouponModal = ({ showDeletModal, setShowDeletModal, fetchCoupons }: any) => {
  return (
    <Modal
      title='Eliminar Cupon'
      open={showDeletModal.visible}
      onOk={() => {
        if (!showDeletModal.id) {
          setShowDeletModal({ visible: false, id: undefined })
        } else {
          deleteCoupon(showDeletModal.id)
            .then(({ data }) => {
              fetchCoupons();
              toast.success("¡Cupón eliminado con éxito!");
              setShowDeletModal({ visible: false, id: undefined })
            })
            .catch((error) => {
              toast.error('Opps! Ocurrio un error.')
              console.log(error)
            })
        }
      }}
      onCancel={() => setShowDeletModal({ visible: false, id: undefined })}
    >
      <p>Estas seguro que desea eliminar este cupon?.
        Una vez eliminado no se podra recuperar.</p>
    </Modal >
  )
}