import { BsCash, BsCreditCard2Back } from 'react-icons/bs';
import { type ClassValue, clsx } from "clsx";
import { AiOutlineBank } from 'react-icons/ai';
import { twMerge } from "tailwind-merge";
import { Tag, Tooltip } from "antd";
import * as XLSX from 'xlsx';
import axios from "axios";

import { IProduct } from "../interfaces/product";
import { IOrder } from "../interfaces/order";


export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatPrice({ is_offer_quantity, offer_quantity_price, is_offer, offert_price, price }: IProduct) {
  if (is_offer_quantity) {
    return offer_quantity_price;
  } else if (is_offer) {
    return offert_price;
  } else {
    return price;
  }
}

export function formatSubtotalPrice({ is_offer_quantity, offer_quantity, offer_quantity_price, is_offer, offert_price, price, quantity = 1 }: IProduct) {
  if (is_offer_quantity) {
    const finalPrice = quantity >= offer_quantity ? quantity * offer_quantity_price : quantity * price
    return finalPrice;
  } else if (is_offer) {
    return offert_price * quantity;
  } else {
    return price * quantity;
  }
}


export const orderToXLS = (heads: any, orders: any) => {
  const createXLSLFormatObj = []
  createXLSLFormatObj.push(heads)
  orders.forEach((order: IOrder) => {
    createXLSLFormatObj.push(order)
  })
  /* File Name */
  const filename = `Lista de Ordenes Cuatro Carnes ${new Date().toISOString().split('T')[0]} .xlsx`
  /* Sheet Name */
  const wsName = 'orders'

  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj)

  XLSX.utils.book_append_sheet(wb, ws, wsName)

  /* Write workbook and Download */
  XLSX.writeFile(wb, filename)
}


export const uploadFile = (file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", "prueba");

  axios
    .post('https://api.cloudinary.com/v1_1/alexispavon010/image/upload', formData)
    .then((response) => {
      return (response.data.secure_url);
    })
    .catch((error) => {
      console.log(error);
      return null
    });
}

export const renderPaymentMethod = (method: string) => {
  let icon
  let text
  let color

  switch (method) {
    case 'credit_card':
      icon = <BsCreditCard2Back size={16} />
      text = 'Tarjeta de Credito'
      color = 'cyan'
      break;

    case 'cash':
      icon = <BsCash size={16} />
      text = 'Efectivo'
      color = 'green'
      break;

    case 'debit_card':
      icon = <BsCreditCard2Back size={16} />
      text = 'Tarjeta de Debito'
      color = 'blue'
      break;

    case 'bank_transfer':
      icon = <AiOutlineBank size={16} />
      text = 'Transferencia Bancaria'
      color = 'geekblue'
      break;

  }

  return (
    <Tooltip title={text}>
      <Tag
        color={color}
        icon={icon}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '4px'
        }}
      >
        {text}
      </Tag>
    </Tooltip >
  )
}

export const sendWhatsAppMessage = (order: IOrder) => {
  const { username, phone, uniqueID, total, items, sub_total, discount } = order as IOrder;

  const pedidoDetalle = items.map((item: any) => {
    const itemTotal = item.is_offer_quantity && item.quantity >= item.offer_quantity
      ? item.offer_quantity_price * item.quantity // Usar el precio de oferta
      : item.price * item.quantity; // Usar el precio regular
    return `${item.quantity} x ${item.title} ($${(itemTotal).toLocaleString('es-AR')})`;
  });

  const message = `¡Gracias *${username}* por hacer tu pedido en *Cuatro Carnes*!\n\nNúmero de orden: *#${uniqueID}*\n\n*Detalle del pedido:*\n- ${pedidoDetalle.join('\n- ')}\n\nSubtotal: *$${sub_total}*.\nDescuentos: *$${discount}*.\nTotal: *$${total}*.`;

  const urlParams = new URLSearchParams();
  urlParams.set('phone', phone);
  urlParams.set('text', message);

  const url = `https://api.whatsapp.com/send?${urlParams.toString()}`;

  window.open(url)
}