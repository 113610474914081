import { Drawer, Button, Input, InputNumber, Switch, Select, Form } from 'antd'
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { z } from 'zod';

import { createCoupon, getCouponById, updateCoupon } from '../../api/coupons';
import { ECuponDiscount } from '../../interfaces/coupon';
import { useUiContext } from '../../context/ui';

const couponSchema = z.object({
  name: z.string().min(1, { message: 'El nombre es requerido' }),
  discount: z.number().min(0, { message: 'El descuento debe ser un número positivo' }),
  description: z.string().min(1, { message: 'La descripción es requerida' }),
  user_id: z.string().min(1, { message: 'El ID de usuario es requerido' }),
  is_active: z.boolean(),
  // min_amount: z.number().min(0, { message: 'El monto mínimo debe ser un número positivo' }),
  // cap_amount: z.number().min(0, { message: 'El monto máximo debe ser un número positivo' }),
  discount_value: z.number().min(0, { message: 'El valor de descuento debe ser un número positivo' }),
  discount_type: z.nativeEnum(ECuponDiscount)
});

type CouponFormValues = z.infer<typeof couponSchema>;

export const CouponDrawer = ({ fetchCoupons }: any) => {
  const { showCuponModal, setShowCuponModal } = useUiContext()
  const [loading, setLoading] = useState(false)
  const message = showCuponModal.id ? 'Actualizar' : 'Cupón creado con éxito!'

  const [form] = Form.useForm();

  const handleFinish = (values: CouponFormValues) => {
    setLoading(true);
    try {
      if (!showCuponModal.id) {
        createCoupon(values)
          .then((response) => {
            toast.success(message);
            form.resetFields();
            fetchCoupons()
            handleClose();
          })
          .catch((error) => {
            toast.error('¡Oops! Algo salió mal.');
            console.error(error);
          })
          .finally(() => setLoading(false));
      }
      else {
        updateCoupon(showCuponModal.id, values)
          .then(() => {
            toast.success(message);
            form.resetFields();
            fetchCoupons()
            handleClose();
          })
          .catch((error) => {
            toast.error('¡Oops! Algo salió mal.');
            console.error(error);
          })
          .finally(() => setLoading(false));
      }
    } catch (err) {
      toast.error('Error al procesar el formulario');
      console.error(err);
      setLoading(false);
    }
  };

  const handleClose = () => {
    setShowCuponModal({ id: null, visible: false })
  }

  useEffect(() => {
    form.resetFields()
    if (!showCuponModal.id) return
    setLoading(true)
    getCouponById(showCuponModal.id)
      .then(({ data }) => {
        form.setFieldsValue(data);
      })
      .catch((error) => {
        toast.error('¡Oops! Algo salió mal.');
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, [showCuponModal])


  return (
    <Drawer
      title="Crear Nuevo Cupón"
      width={720}
      onClose={handleClose}
      open={showCuponModal.visible}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button disabled={loading} onClick={handleClose} style={{ marginRight: 8 }}>
            Cancelar
          </Button>
          <Button loading={loading} onClick={() => form.submit()} type="primary">
            Guardar
          </Button>
        </div>
      }
    >
      <Form layout="vertical" form={form} onFinish={handleFinish}>
        <h3 className='mb-4 text-zinc-500 text-sm'>Sus cupones activos aparecerán en la seccion cupones de su aplicación.</h3>
        <Form.Item
          name="name"
          label="Nombre"
          rules={[{ required: true, message: 'Por favor ingrese el nombre del cupón.' }]}
        >
          <Input placeholder="Ingrese el nombre del cupón" />
        </Form.Item>
        <Form.Item
          name="price"
          label="Precio en puntos"
          rules={[{ required: true, message: 'Por favor ingrese el precio.' }]}
        >
          <InputNumber
            placeholder="Ingrese el precio"
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          name="discount"
          label="Descuento (%)"
          rules={[{ required: true, message: 'Por favor ingrese el descuento.' }]}
        >
          <InputNumber
            placeholder="Ingrese el descuento"
            style={{ width: '100%' }}
            min={0}
            max={100}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Descripción"
          rules={[{ required: true, message: 'Por favor ingrese la descripción de su descuento.' }]}
        >
          <Input.TextArea placeholder="Ingrese la descripción" rows={4} />
        </Form.Item>
        <Form.Item
          name="is_active"
          label="Estado"
          valuePropName="checked"
        >
          <Switch checkedChildren="Activo" unCheckedChildren="Inactivo" />
        </Form.Item>
        {/* <Form.Item
          name="min_amount"
          label="Monto Mínimo"
          rules={[{ required: true, message: 'Por favor ingrese el monto mínimo' }]}
        >
          <InputNumber
            placeholder="Ingrese el monto mínimo"
            style={{ width: '100%' }}
          />
        </Form.Item> */}
        {/* <Form.Item
          name="cap_amount"
          label="Monto Máximo"
          rules={[{ required: true, message: 'Por favor ingrese el monto máximo' }]}
        >
          <InputNumber
            placeholder="Ingrese el monto máximo"
            style={{ width: '100%' }}
          />
        </Form.Item> */}
        <Form.Item
          name="discount_value"
          label="Valor del Descuento %"
          rules={[{ required: true, message: 'Por favor ingrese el valor del descuento' }]}
        >
          <InputNumber
            placeholder="Ingrese el valor del descuento"
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          name="discount_type"
          label="Tipo de Descuento"
          rules={[{ required: true, message: 'Por favor seleccione el tipo de descuento' }]}
        >
          <Select placeholder="Seleccione el tipo de descuento">
            <Select.Option value="PERCENT">Porcentual</Select.Option>
            <Select.Option value="FIXED">Fijo</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Drawer>
  )
}
