import { createContext, useContext, useState } from 'react';

import { IProduct } from '../interfaces/product';
import { IOrder } from '../interfaces/order';
import { IUser } from '../interfaces/user';
import { ICoupon } from '../interfaces/coupon';

interface UiProviderProps {
  children: JSX.Element | JSX.Element[]
}

interface User {
  visible: boolean
  user: null | IUser
}

interface Order {
  visible: boolean
  order: null | IOrder
}

interface Cupon {
  visible: boolean
  id: null | string
}

interface Product {
  visible: boolean
  product: undefined | IProduct
}

interface Option {
  visible: boolean
  id: null | string
}

interface UiContextProps {
  openMenu: boolean
  showDeletModal: Option
  showOrderModal: Order
  showDeletOption: Option
  showOptionsModal: Option
  showOptionsDrawer: Option
  showProductDrawer: Product
  showCategoryDrawer: Option
  showDeletCategory: Option
  showInvoiceModal: Order
  showDeletuser: User
  showUpdateUser: User
  showCuponModal: Cupon
  setShowCuponModal: (state: Cupon) => void
  setShowUpdateUser: (state: User) => void
  setOpenMenu: (state: boolean) => void
  setShowDeletuser: (state: User) => void
  setShowDeletModal: (state: Option) => void
  setShowOrderModal: (state: Order) => void
  setShowDeletOption: (state: Option) => void
  setShowOptionsDrawer: (state: Option) => void
  setShowOptionsModal: (state: Option) => void
  setShowDeletCategory: (state: Option) => void
  setShowProductDrawer: (state: Product) => void
  setShowCategoryDrawer: (state: Option) => void
  setSetshowInvoiceModal: (state: Order) => void
}

const Context = createContext<UiContextProps | undefined>(undefined)

export const useUiContext = (): UiContextProps => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('Order context debe ser utilizado dentro de un provider.');
  }
  return context;
};

export const UiProvider = ({ children }: UiProviderProps) => {
  const [showInvoiceModal, setSetshowInvoiceModal] = useState<Order>({ visible: false, order: null })
  const [showOrderModal, setShowOrderModal] = useState<Order>({ visible: false, order: null })
  const [showProductDrawer, setShowProductDrawer] = useState<Product>({ visible: false, product: undefined })
  const [showOptionsModal, setShowOptionsModal] = useState<Option>({ visible: false, id: null })
  const [showOptionsDrawer, setShowOptionsDrawer] = useState<Option>({ visible: false, id: null })
  const [showDeletOption, setShowDeletOption] = useState<Option>({ visible: false, id: null })
  const [showDeletCategory, setShowDeletCategory] = useState<Option>({ visible: false, id: null })
  const [showDeletuser, setShowDeletuser] = useState<User>({ visible: false, user: null })
  const [showUpdateUser, setShowUpdateUser] = useState<User>({ visible: false, user: null })
  const [showCuponModal, setShowCuponModal] = useState<Cupon>({ visible: false, id: null })
  const [showDeletModal, setShowDeletModal] = useState<Option>({ visible: false, id: null })
  const [openMenu, setOpenMenu] = useState(false)
  const [showCategoryDrawer, setShowCategoryDrawer] = useState<Option>({ visible: false, id: null })

  const UiContextValue: UiContextProps = {
    openMenu,
    showDeletuser,
    showCuponModal,
    showDeletModal,
    showUpdateUser,
    showOrderModal,
    showDeletOption,
    showOptionsModal,
    showInvoiceModal,
    showOptionsDrawer,
    showDeletCategory,
    showProductDrawer,
    showCategoryDrawer,
    setOpenMenu,
    setShowDeletuser,
    setShowUpdateUser,
    setShowCuponModal,
    setShowDeletModal,
    setShowDeletOption,
    setShowOrderModal,
    setShowOptionsModal,
    setShowDeletCategory,
    setShowOptionsDrawer,
    setShowProductDrawer,
    setShowCategoryDrawer,
    setSetshowInvoiceModal
  }

  return (
    <Context.Provider value={UiContextValue}>
      {children}
    </Context.Provider>
  )
}
