import { Avatar, Dropdown, MenuProps, Space } from 'antd';

import { useAuthContext } from '../../context/auth';


export const UserNavbar = () => {
  const { user, handleLogout } = useAuthContext()

  const items: MenuProps['items'] = [
    {
      key: '1',
      type: 'group',
      label: user?.email ?? "",
      children: [
        {
          key: '2',
          label: 'Cerrar Sesion',
          onClick: handleLogout
        },
      ],
    },
  ];

  return (
    <Dropdown menu={{ items }}>
      <Avatar>
        {user?.username.charAt(0).toUpperCase()}
      </Avatar>
    </Dropdown>
  )
}
