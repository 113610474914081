import { Form, Input, Modal, Select } from 'antd'
import { useEffect, useState } from 'react'
import { toast } from 'sonner'

import { useUsersContext } from '../../context/users'
import { useUiContext } from '../../context/ui'
import { IUser } from '../../interfaces/user'
import { Update } from '../../api/auth'

export const UpdateUser = () => {
  const { handleFilterChange, page, limit } = useUsersContext()
  const { showUpdateUser, setShowUpdateUser } = useUiContext()
  const { user, visible } = showUpdateUser
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const onFinish = ({ user_birthday, ...rest }: IUser) => {
    if (!user?._id) return

    setLoading(true)

    const formattedBirthday = user_birthday
      ? new Date(user_birthday).toISOString()
      : null

    Update(user?._id, {
      user_birthday: formattedBirthday,
      ...rest,
    })
      .then((res) => {
        handleFilterChange([["page", page], ["limit", limit]])
        toast.success('¡Usuario actualizado con éxito!')
        setShowUpdateUser({ visible: false, user: null })
      })
      .catch((error) => {
        console.error(error)
        toast.error('¡Error al actualizar el usuario!')
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    form.resetFields()
    if (!user) return

    const formattedBirthday = user.user_birthday
      ? new Date(user.user_birthday).toISOString().slice(0, 10)
      : null

    form.setFieldsValue({
      ...user,
      user_birthday: formattedBirthday,
    })
  }, [user, form])

  return (
    <Modal
      title="Opciones de usuario"
      confirmLoading={loading}
      onOk={() => form.submit()}
      open={visible}
      onCancel={() => setShowUpdateUser({ visible: false, user: null })}
      okText="Actualizar"
      cancelText="Cancelar"
    >
      <Form
        onFinish={onFinish}
        layout="vertical"
        form={form}
        requiredMark={false}
        initialValues={{
          fleet: 'default',
          deadline: new Date(),
        }}
      >
        <div className="grid md:grid-cols-2 gap-x-6">
          <Form.Item label="Nombre de Usuario" name="username">
            <Input type="text" />
          </Form.Item>
          <Form.Item label="Teléfono" name="phone">
            <Input type="text" />
          </Form.Item>
          <Form.Item label="Rol" name="role">
            <Select
              options={[
                { label: 'Administrador', value: 'admin' },
                { label: 'Cliente', value: 'client' },
              ]}
            />
          </Form.Item>
          <Form.Item label="Fecha de nacimiento" name="user_birthday">
            <Input type="date" />
          </Form.Item>
          <Form.Item label="Puntuación" name="score">
            <Input type="number" />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}
