import { createContext, useContext, useEffect, useState } from 'react'

import { ICategory } from '../interfaces/category'
import { getCategories } from '../api/category'
import { getStats } from '../api/stats'

interface CategoriesProviderProps {
  children: JSX.Element | JSX.Element[]
}

interface CategoriesContextProps {
  total: number
  page?: number
  limit?: number
  loading: boolean
  categories: ICategory[]
  onPageChange: (page: number) => void
  onPageSizeChange: (current: number, size: number) => void
  handleGetCategories: () => void
}

const Context = createContext<CategoriesContextProps | undefined>(undefined)

export const useCategoriesContext = (): CategoriesContextProps => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('Categories context debe ser utilizado dentro de un provider.');
  }
  return context;
};

export const CategoriesProvider = ({ children }: CategoriesProviderProps) => {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(true)

  const handleGetCategories = () => {
    setLoading(true)
    getCategories()
      .then(({ data }) => {
        setCategories(data)
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false))
  }

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onPageSizeChange = (current: any, size: any) => {
    setLimit(size);
  };

  useEffect(() => {
    handleGetCategories()
  }, [page, limit])

  const categoriesContextValue: CategoriesContextProps = {
    categories,
    page,
    limit,
    total: 100,
    loading,
    onPageChange,
    onPageSizeChange,
    handleGetCategories,
  }

  return (
    <Context.Provider value={categoriesContextValue}>
      {children}
    </Context.Provider>
  )
}
