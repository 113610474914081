import {
  Card,
  Row,
  Col,
  Button,
  Space,
  Table,
  Dropdown,
  MenuProps,
  Input,
} from "antd";
import { MdOutlineComputer } from "react-icons/md";
import { ColumnsType } from "antd/es/table";
import { BiCategory, BiPencil } from "react-icons/bi";

import { ICategory } from "../../../interfaces/category";
import { useOrderContext } from "../../../context/order";
import { useUiContext } from "../../../context/ui";
import { CategoryDrawer } from "../../../components/drawer/category-drawer";
import { DeleteCatagoryModal } from "../../../components/modals/delet-category";
import { useCategoriesContext } from "../../../context/categories";

const { Search } = Input;

export const CategoriesScreen = () => {
  const { loading } = useOrderContext();
  const { categories } = useCategoriesContext();
  const { setShowCategoryDrawer, setShowDeletCategory } = useUiContext();

  const getMenuItems = (record: ICategory): MenuProps["items"] => [
    {
      key: "1",
      icon: <BiPencil size={14} />,
      label: "Modificar",
      onClick: () => setShowCategoryDrawer({ visible: true, id: record._id }),
    },
    {
      key: "2",
      icon: <MdOutlineComputer size={14} />,
      label: "Eliminar",
      onClick: () => setShowDeletCategory({ visible: true, id: record._id }),
    },
  ];

  const columns: ColumnsType<any> = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Descripción",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Acciones",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <Dropdown
          menu={{ items: getMenuItems(record) }}
          placement="bottomRight"
          trigger={["click"]}
        >
          <Button>Acciones</Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="flex-1 space-y-4 p-4 md:p-8 pt-6">
      <div className="flex items-center justify-start space-x-3">
        <BiCategory size={24} />
        <h1 className="font-semibold text-2xl">Categorías</h1>
      </div>
      <Card>
        <Row gutter={[0, 12]}>
          <Col xs={24} md={6} lg={6}>
           <span className="mr-2">•</span>Crea y gestiona categorías para tus productos
          </Col>
          <Col flex={1}></Col>
          <Col>
            <Space wrap>
              <Button
                onClick={() =>
                  setShowCategoryDrawer({ id: null, visible: true })
                }
              >
                Crear Categoría
              </Button>
            </Space>
          </Col>
        </Row>
      </Card>
      <Table
        loading={loading}
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={categories}
        rowKey="_id"
      />
      <CategoryDrawer />
      <DeleteCatagoryModal />
    </div>
  );
};
