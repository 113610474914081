import { useState } from 'react'
import { toast } from 'sonner';
import { Modal } from 'antd';

import { deleteCategory } from '../../api/category';
import { useUiContext } from '../../context/ui';
import { useCategoriesContext } from '../../context/categories';

export const DeleteCatagoryModal = () => {
  const { handleGetCategories } = useCategoriesContext()
  const { showDeletCategory, setShowDeletCategory } = useUiContext()
  const [loading, setLoading] = useState(false)


  const handleOk = () => {
    if (!showDeletCategory.id) return
    setLoading(true)
    deleteCategory(showDeletCategory.id)
      .then(({ data }) => {
        toast.success('Categoría eliminada con exito.')
        handleGetCategories()
        handleCancel();
      })
      .catch((error) => {
        console.log(error)
        toast.error('Opps Algo salio mal')
      })
      .finally(() => setLoading(false))
  };

  const handleCancel = () => {
    setShowDeletCategory({ visible: false, id: null });
  };

  return (
    <Modal
      title="Eliminar Categoría"
      confirmLoading={loading}
      open={showDeletCategory.visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <p>Estas seguro que desea eliminar esta categoría?.
        Una vez eliminado no se podra recuperar.</p>
    </Modal>
  )
}
