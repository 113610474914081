import { Button, Card, Form, Input, Select } from 'antd'
import { useState } from 'react'
import { getOrders } from '../../../api/orders'
import moment from 'moment'
import { IOrder } from '../../../interfaces/order'
import { orderToXLS } from '../../../libs/utils'
import { toast } from 'sonner'
import { Link } from 'react-router-dom'
import { ArrowLeft } from 'lucide-react'

export const ReportScreen = () => {
  const [loading, setLoading] = useState(false)

  const onSubmit = (values: any) => {
    const startDate = new Date(values.start_date)
    const endDate = new Date(values.end_date)
    endDate.setHours(23, 59, 59, 999)
    setLoading(true)
    getOrders({
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
      limit: values.limit
    })
      .then(({ data }) => {
        const formatOrders = data.results?.map((order: IOrder) =>
          [`${order.uniqueID}`, `${moment(order.createdAt).format('DD/MM/YYYY, h:mm:ss a')}`, `$${order.total.toFixed(2)}`, `${order.username}`, `${order.email}`, `${order.status}`]
        )
        orderToXLS(
          ['Nro', 'Fecha y Hora', 'Monto', 'Cliente', 'Correo', 'Estado'],
          formatOrders
        )
        setLoading(false)
        toast.success('Exito al descargar!.');
      })
      .catch((error) => {
        console.log(error)
        toast.error("¡Ups! Hubo un error.");
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="flex-1 space-y-4 p-4 md:p-8 pt-6">
      <div className="flex items-center justify-between space-y-2">
        <Link
          className="flex gap-2"
          to='/'
        >
          <ArrowLeft />
          Volver
        </Link>
      </div>
      <Card>
        <Form
          onFinish={onSubmit}
          layout='vertical'
          initialValues={{
            limit: '100',
            start_date: moment().format('YYYY-MM-DD'),
            end_date: moment().endOf('day').format('YYYY-MM-DD')
          }}
        >
          <div className="md:grid md:grid-cols-3 gap-8 items-center">
            <Form.Item
              label='Fecha de inicio'
              name='start_date'
            >
              <Input type='date' />
            </Form.Item>
            <Form.Item
              label='Fecha final'
              name='end_date'
            >
              <Input type='date' />
            </Form.Item>
            <Form.Item
              label='Limite'
              name='limit'
              initialValue={100}
            >
              <Select
                options={[
                  { value: 100, label: 100 },
                  { value: 200, label: 200 },
                  { value: 500, label: 500 },
                  { value: 1000, label: 1000 },
                ]}
              />
            </Form.Item>
            <Form.Item>
              <Button loading={loading} htmlType='submit'>Descargar</Button>
            </Form.Item>
          </div>
        </Form>
      </Card>
    </div>
  )
}
