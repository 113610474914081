import instance from ".";
import { ICoupon } from "../interfaces/coupon";

type CreateCoupon = Pick<ICoupon, 'name' | 'discount' | 'description' | 'user_id' | 'discount_value' | 'discount_type'>;
type UpdateCoupon = Partial<CreateCoupon>;
type ActivateCoupon = Pick<ICoupon, 'is_active'>;

export const getCoupons = (query: Partial<ICoupon>) => {
  const params = new URLSearchParams(query as {});
  return instance.get(`coupons?${params.toString()}`);
};

export const getCouponById = (id: string) => {
  return instance.get(`coupons/${id}`);
};

export const createCoupon = (payload: CreateCoupon) => {
  return instance.post('coupons', payload);
};

export const updateCoupon = (id: string, payload: UpdateCoupon) => {
  return instance.patch(`coupons/${id}`, payload);
};

export const activateCoupon = (id: string, payload: ActivateCoupon) => {
  return instance.patch(`coupons/${id}`, payload);
};

export const deleteCoupon = (id: string) => {
  return instance.delete(`coupons/${id}`);
};
