export const STATUSES = [
  {
    value: 'PENDING',
    label: 'Pendiente',
    color: 'blue'
  },
  {
    value: 'COMPLETED',
    label: 'Completado',
    color: 'green'
  },
  {
    value: 'DELIVERED',
    label: 'Entregado',
    color: 'cyan'
  },
  {
    value: 'CANCELLED',
    label: 'Cancelado',
    color: 'red'
  },
]