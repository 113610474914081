import React, { createContext, useContext, useEffect, useState } from 'react'
import { getOptions } from '../api/options';

interface OptionProviderProps {
  children: JSX.Element | JSX.Element[]
}

const Context = createContext<undefined | any>(undefined)

export const useOptionsContext = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('Options context debe ser utilizado dentro de un provider.');
  }
  return context;
};

export const OptionProvider = ({ children }: OptionProviderProps) => {
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)

  const handleGetOptions = () => {
    getOptions({})
      .then(({ data }) => {
        setOptions(data)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    handleGetOptions()
  }, [])

  return (
    <Context.Provider value={{
      options,
      loading,
      handleGetOptions
    }}>
      {children}
    </Context.Provider>
  )
}
