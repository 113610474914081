import { ReactNode, createContext, useContext, useEffect, useState } from 'react'

import { Login, Register, UserLogin, UserRegister, Verify } from '../api/auth';
import { IUser } from '../interfaces/user';
import { BASE_URL } from '../api';

export type User = Pick<IUser, 'email' | 'username'>;

interface AuthContextProps {
  user: User | undefined | null;
  loading: boolean;
  uiLoading: boolean;
  loginErrors: any;
  handleLogin: (user: UserLogin) => void;
  handleRegister: (user: UserRegister) => void;
  handleLogout: () => void;
  signinWithGoogle: () => void;
  handleGetUserToken: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

const Context = createContext<AuthContextProps | undefined>(undefined);

export const useAuthContext = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('Auth context debe ser utilizado dentro de un provider.');
  }
  return context;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User | undefined | null>(undefined)
  const [loginErrors, setLoginErrors] = useState<any>(undefined)
  const [uiLoading, setUiLoading] = useState(true)
  const [loading, setLoading] = useState(false)

  const token = localStorage.getItem('token')

  const handleLogin = (user: UserLogin) => {
    setLoading(true)
    Login(user)
      .then(({ data }) => {
        console.log(data)
        setUser(data)
        localStorage.setItem('token', data.token)
      })
      .catch((error) => {
        console.log(error)
        if (error.response) {
          const { data } = error.response
          const message = data.message;
          const errorsArray = Array.isArray(message) ? message : [message];
          setLoginErrors(errorsArray);
          setUser(null)
          setTimeout(() => {
            setLoginErrors([])
          }, 3000);
        }
      })
      .finally(() => setLoading(false))
  }

  const handleRegister = (user: UserRegister) => {
    setUiLoading(true)
    Register(user)
      .then(({ data }) => {
        setUser(data)
        localStorage.setItem('token', data.token)
      })
      .catch((error) => console.log(error))
      .finally(() => setUiLoading(false))
  }


  const handleLogout = () => {
    setUser(null)
    localStorage.removeItem('token')
    window.location.reload()
  }

  const handleGetUserToken = () => {
    setUiLoading(false)
    if (!token) return
    setUiLoading(true)
    Verify()
      .then(({ data }) => {
        localStorage.setItem('token', data.token)
        setUiLoading(false)
        setUser(data)
      })
      .catch((err) => {
        console.log(err)
        setUser(null)
        setUiLoading(false)
      })
      .finally(() => setUiLoading(false))
  }


  const signinWithGoogle = async () => {
    setUiLoading(true)
    try {
      window.open(`${BASE_URL}/auth/google`)
      // const result: any = await signInWithPopup(FirebaseAuth, googleAuthProvider)
      // const { displayName, email, accessToken } = result.user;
      // localStorage.setItem('token', accessToken)
      // setUiLoading(false)
      // setUser({
      //   email,
      //   username: displayName,
      //   phone: ''
      // })
      // console.log(result.user)
    } catch (error) {
      console.log(error)
    } finally {
      setUiLoading(false)
    }
  }

  useEffect(() => {
    handleGetUserToken()
  }, [])

  const authContextValue: AuthContextProps = {
    user,
    loading,
    uiLoading,
    loginErrors,
    handleLogin,
    handleRegister,
    handleLogout,
    signinWithGoogle,
    handleGetUserToken,
  };

  return (
    <Context.Provider value={authContextValue}>
      {children}
    </Context.Provider>
  )
}
