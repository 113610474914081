import { Route, Routes } from "react-router-dom"

import { HomeScreen, OrderDetails, ProductDetails } from "../screens"
import { ReportScreen } from "../screens/dashboard/order/order-reports"
import { CategoriesScreen } from "../screens/dashboard/categories"
import { OptionScreen } from "../screens/dashboard/options"
import { UsersScreen } from "../screens/dashboard/users"
import { CouponScreen } from "../screens/dashboard/coupons"
import { StatsScreen } from "../screens/dashboard/stats"


export const DashboardRouter = () => {
  return (
    <Routes>
      <Route path="/stats" element={<StatsScreen />}/>
      <Route path="/" element={<HomeScreen />} />
      <Route path='order'>
        <Route path="reports" element={<ReportScreen />} />
        <Route path=":id" element={<OrderDetails />} />
      </Route>

      <Route path="/products">
        <Route path="" element={<ProductDetails />} />
        {/* <Route path=":id" element={<ProductForm />} /> */}
      </Route>

      <Route path="/categories" element={<CategoriesScreen />} />
      <Route path="/options" element={<OptionScreen />} />
      <Route path="/coupons" element={<CouponScreen />} />
      <Route path="/users" element={<UsersScreen />} />
    </Routes>
  )
}
