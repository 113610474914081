import { UiProvider } from './ui'
import { AuthProvider } from './auth'
import { OrderProvider } from './order'
import { OptionProvider } from './options'
import { ProductProvider } from './product'
import { UsersProvider } from './users'
import { CategoriesProvider } from './categories'

interface RootProviderProps {
  children: JSX.Element | JSX.Element[]
}

export const RootProvider = ({ children }: RootProviderProps) => {
  return (
    <AuthProvider>
      <UiProvider>
        <OrderProvider>
          <OptionProvider>
            <ProductProvider>
              <CategoriesProvider>
                <UsersProvider>
                  {children}
                </UsersProvider>
              </CategoriesProvider>
            </ProductProvider>
          </OptionProvider>
        </OrderProvider>
      </UiProvider>
    </AuthProvider>
  )
}
