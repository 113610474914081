interface TourStep {
  selector: string
  content: string
}

const steps: TourStep[] = [
  {
    selector: '[data-tut="first-step"]',
    content:
      'Este es el menú principal, donde puedes acceder a diferentes opciones de gestión para tu comercio.',
  },
  {
    selector: '.ant-avatar',
    content:
      'Este es el usuario con la sesión activa, aquí tambien puedes cerrar sesión.',
  },
  {
    selector: '[data-tut="third-step"]',
    content:
      'Cambia de modo claro a oscuro con este botón.',
  },
  {
    selector: '[data-tut="fourth-step"]',
    content:
      'Aquí puedes ver estadísticas generales de sus pedidos',
  },
  {
    selector: '[data-tut="fifth-step"]',
    content:
      'Aquí puedes buscar por número de pedido, nombre, correo, telefono.',
  },
  {
    selector: '[data-tut="sixth-step"]',
    content:
      'Aquí puedes buscar pedidos entre períodos.',
  },
  {
    selector: '[data-tut="seven-step"]',
    content:
      'Aquí puedes descargar reportes de tus pedidos en formato .xls entre períodos.',
  },
  {
    selector: '[data-tut="eight-step"]',
    content:
      'Actualiza la tabla de pedidos o establece el período de refresco por tiempo tocando en los tres puntos.',
  },
  {
    selector: '[data-tut="nine-step"]',
    content:
      'Aquí puedes ver todos los pedidos que has recibido',
  },
  {
    selector: '[data-tut="ten-step"]',
    content:
      'Envía el detalle de pedido por WhatsApp al cliente, recuerda tener una sesión activa de WhatsApp Web en tu explorador.',
  },
  {
    selector: '[data-tut="eleven-step"]',
    content:
      'Este es un panel de edición rápida de pedidos para actualizar estado, asignar color y fecha de entrega.',
  },
  {
    selector: '[data-tut="twelve-step"]',
    content:
      'Accedé al detalle del pedido y gestionalo de manera simple, aquí podras imprimir la comanda del pedido.',
  },
  {
    selector: '[data-tut="thirteen-step"]',
    content:
      'Enviale la factura por correo al cliente directamente desde aquí.',
  },
]

export default steps
