import { createSchemaFieldRule } from "antd-zod";
import { Button, Form, Input } from 'antd';
import { z } from "zod";

import { useAuthContext } from "../../context/auth";
import { cn } from "../../libs/utils";

const formSchema = z.object({
  email: z.string({ required_error: 'El correo electrónico es requerido' }).email({ message: "Ingrese un correo valido." }),
  password: z.string({ required_error: 'La contraseña es requerida.' }),
});

const rule = createSchemaFieldRule(formSchema);
type UserFormValue = z.infer<typeof formSchema>;

export const LoginScreen = () => {
  const { loading, handleLogin, loginErrors } = useAuthContext()

  const onSubmit = async (data: UserFormValue) => {
    handleLogin(data)
  };

  return (
    <div className="relative h-screen flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
      <a
        href="/examples/authentication"
        className={cn(
          "absolute right-4 hidden top-4 md:right-8 md:top-8",
        )}
      >
        Login
      </a>
      <div className="relative hidden h-full flex-col bg-muted p-10 dark:border-r lg:flex">
        <div className="absolute inset-0 bg-login_image bg-no-repeat bg-cover bg-center" />
        <div className="relative z-20 flex items-center text-lg font-medium">
          <img className="mr-2 h-12" src="logo-cuatro-carnes.svg" alt="Logo Cuatro Carnes" />
        </div>
        <div className="relative z-20 mt-auto">
          <blockquote>
            <p className="text-xl text-[#F7F0F0]">
              Cuatro Carnes
            </p>
            <footer className="text-sm text-[#F7F0F0]">2024</footer>
          </blockquote>
        </div>
      </div>
      <div className="p-4 lg:p-8 h-full flex items-center">
        <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
          <div className="flex flex-col text-center space-y-4">
            <h1 className="text-xl font-semibold">Dashboard Administrativo</h1>
            <h2 className="text-3xl font-semibold tracking-tight">
              Iniciar Sesión
            </h2>
            <h3 className="text-light italic text-sm text-zinc-500">Ingrese unicamente con llaves de administrador</h3>
          </div>
          {loginErrors && loginErrors.map((r: any, i: number) => (
            <p key={i} className="text-red-500 text-center">{r}</p>
          ))}
          <Form
            layout="vertical"
            requiredMark={false}
            onFinish={onSubmit}
          >
            <Form.Item
              rules={[rule]}
              label="Correo electrónico"
              name="email"
            >
              <Input size="large" />
            </Form.Item>

            <Form.Item
              rules={[rule]}
              label="Contraseña"
              name="password"
            >
              <Input.Password size="large" />
            </Form.Item>

            <Form.Item>
              <Button
                loading={loading}
                htmlType="submit"
                size="large"
                className="w-full mt-2"
              >
                Iniciar sesión
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div >
  )
}
