import { Button, Drawer, Form, Input, List, Modal, Space, Tooltip, Typography } from 'antd'
import { BsPencil, BsTrash } from 'react-icons/bs'
import { useEffect, useState } from 'react';
import { toast } from 'sonner';

import { createOption, getOptionById, updateOption } from '../../api/options';
import { createItem, deleteItem, getItem, updateItem } from '../../api/item';
import { useUiContext } from '../../context/ui';
import { useOptionsContext } from '../../context/options';


const { useForm } = Form;

export const OptionDrawer = () => {
  const { showOptionsDrawer, setShowOptionsDrawer } = useUiContext()
  const { handleGetOptions } = useOptionsContext()
  const [openEditModal, setOpenEditModal] = useState({ visible: false, itemId: undefined });
  const [openDeletedModal, setOpenDeletedModal] = useState({ visible: false, itemId: undefined });
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [quantity, setQuantity] = useState(1)
  const [form] = useForm()

  const handleFinish = (values: any) => {
    setLoading(true)
    if (!showOptionsDrawer.id) {
      createOption({ ...values, quantity: quantity, items: options })
        .then((response) => {
          form.resetFields()
          handleGetOptions()
          handeClose()
        })
        .catch((error) => console.log(error))
        .finally(() => setLoading(false))
    } else {
      updateOption(showOptionsDrawer.id!, values)
        .then((response) => {
          form.resetFields()
          handeClose()
        })
        .catch((error) => console.log(error))
        .finally(() => setLoading(false))
    }
  }

  const handeClose = () => {
    setShowOptionsDrawer({ visible: false, id: null })
  }

  useEffect(() => {
    form.resetFields()
    setOptions([])
    if (!showOptionsDrawer.id) return
    getOptionById(showOptionsDrawer.id)
      .then(({ data }) => {
        console.log(data)
        setOptions(data.items)
        form.setFieldsValue(data)
      })
      .catch((error) => console.log(error))
  }, [showOptionsDrawer.id])

  return (
    <Drawer
      width={400}
      title="Nueva Opción"
      placement="right"
      open={showOptionsDrawer.visible}
      onClose={handeClose}
      footer={
        <Button loading={loading} block type="primary" size="large" onClick={() => form.submit()}>
          Cargar
        </Button>
      }
    >
      <Form
        form={form}
        layout='vertical'
        name="basic"
        initialValues={{
          price: 0
        }}
        onFinish={handleFinish}
        autoComplete="off"
        requiredMark={false}
      >
        <Form.Item
          name="name"
          label="Nombre de la Opción"
          rules={[{ required: true, message: 'Porfavor inserte un nombre.' }]}
        >
          <Input placeholder='Escribe el nombre del producto' size='large' />
        </Form.Item>

        <Form.Item
          label="Cantidad"
          name="quantity"
        >
          <Space.Compact size='large' style={{ width: '100%' }}>
            <Button onClick={() => setQuantity((value) => (value > 1 ? value - 1 : value))} type="primary">-</Button>
            <Input onChange={({ target }) => setQuantity(Number(target.value))} style={{ textAlign: 'center' }} value={quantity} defaultValue={quantity} />
            <Button onClick={() => setQuantity(quantity + 1)} type="primary">+</Button>
          </Space.Compact>
        </Form.Item>
        <Space style={{ width: '100%' }} size='large' direction="vertical">
          <List
            bordered
            dataSource={options}
            renderItem={(item: any) => (
              <List.Item
                actions={[
                  <Tooltip title="Editar">
                    <Button
                      onClick={() => setOpenEditModal({ visible: true, itemId: item._id })}
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      type="text" shape="circle"
                      icon={<BsPencil size={18} />}
                    />
                  </Tooltip>,
                  <Tooltip title="Eliminar">
                    <Button
                      onClick={() => setOpenDeletedModal({ visible: true, itemId: item._id })}
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      type="default" shape="circle"
                      icon={<BsTrash size={18} />}
                    />
                  </Tooltip>,
                ]}
              >
                <Typography.Text>{item.name}</Typography.Text>
                <Typography.Text>${item.price}</Typography.Text>
              </List.Item>
            )}
          />

          <Button
            onClick={() => setOpenModal(true)}
            block
          >
            Agregar un Item
          </Button>
        </Space>
      </Form>
      <NewItemModal id={showOptionsDrawer.id} open={openModal} setOpenModal={setOpenModal} setOptions={setOptions} />
      <DeletedItemModal id={showOptionsDrawer.id} open={openDeletedModal} setOpenDeletedModal={setOpenDeletedModal} setOptions={setOptions} />
      <EditItemModal id={showOptionsDrawer.id} open={openEditModal} setOpenEditModal={setOpenEditModal} setOptions={setOptions} />
    </Drawer>
  )
}

const NewItemModal = ({ id, open, setOpenModal, setOptions }: any) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    form.resetFields()
  }, [open])

  return (
    <Modal
      title='Agregar un Item'
      open={open}
      onOk={() => form.submit()}
      onCancel={() => setOpenModal(false)}
    >
      <Form
        layout='vertical'
        form={form}
        requiredMark={false}
        onFinish={(values: any) => {
          console.log(values)
          if (!id) {
            setOptions((prevState: any) => [...prevState, values])
            setOpenModal(false)
          } else {
            setLoading(true)
            createItem(id, { price: Number(values.price), ...values })
              .then(({ data }) => {
                console.log(data)
                setOptions(data.items);
                toast.success('Item agregado con exito!.')
                setOpenModal(false)
              })
              .catch((error) => {
                console.log(error)
                toast.error('Opps! Ocurrio un error.')
              })
              .finally(() => setLoading(false))
          }
        }}
        onFinishFailed={() => { }}
      >
        <Form.Item
          label="Nombre"
          name="name"
          rules={[{ required: true, message: 'Please input your name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Precio"
          name="price"
          rules={[{ required: true, message: 'Please input your price!' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const EditItemModal = ({ id, open, setOpenEditModal, setOptions }: any) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (open.visible)
      getItem(id, open.itemId)
        .then(({ data }) => {
          console.log(data)
          form.setFieldsValue(data)
        })
        .catch((error) => {
          console.log(error)
          toast.error('Opps! Ocurrio un error.')
        })
  }, [open])


  return (
    <Modal
      title='Editar item'
      open={open.visible}
      okText='Actualizar'
      cancelText='Cancelar'
      onOk={() => form.submit()}
      onCancel={() => setOpenEditModal({ visible: false, itemId: undefined })}
    >
      <Form
        layout='vertical'
        form={form}
        requiredMark={false}
        onFinish={(values: any) => {
          console.log(values)
          updateItem(id, open.itemId, {
            ...values,
            price: Number(values.price)
          })
            .then(({ data }) => {
              setOptions(data.items)
              toast.success('Item actualizado con exito!.')
              setOpenEditModal({ visible: false, itemId: undefined })
            })
            .catch((error) => {
              toast.error('Opss!. Algo salio mal, Vuelva a intentarlo mas tarde.')
              console.log(error)
            })
        }}
        onFinishFailed={() => { }}
      >
        <Form.Item
          label="Nombre"
          name="name"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Precio"
          name="price"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input type='number' />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const DeletedItemModal = ({ id, open, setOptions, setOpenDeletedModal }: any) => {
  return (
    <Modal
      title='Eliminar Item'
      open={open.visible}
      onOk={() => {
        if (!id) {
          setOptions([])
          setOpenDeletedModal({ visible: false, itemId: undefined })
        } else {
          deleteItem(id, open.itemId)
            .then(({ data }) => {
              setOptions(data.items)
              toast.success('Item eliminado con exito!.')
              setOpenDeletedModal({ visible: false, itemId: undefined })
            })
            .catch((error) => {
              toast.error('Opps! Ocurrio un error.')
              console.log(error)
            })
        }
      }}
      onCancel={() => setOpenDeletedModal({ visible: false, itemId: undefined })}
    >
      <p>Estas seguro que desea eliminar este item?.
        Una vez eliminado no se podra recuperar.</p>
    </Modal >
  )
}