import { useLocation, useNavigate } from 'react-router-dom'
import { Drawer, Menu, Tag } from 'antd' // Importa el Tag
import { useUiContext } from '../../context/ui'
import { cn } from '../../libs/utils'
import { BiCategory } from 'react-icons/bi'
import { BsBoxSeam, BsTicketPerforated } from 'react-icons/bs'
import { IoStatsChart } from 'react-icons/io5'
import { MdOutlineComputer } from 'react-icons/md'
import { RiUserSettingsLine } from 'react-icons/ri'
import { TfiMenuAlt } from 'react-icons/tfi'
import { useTheme } from '../../context/theme'

interface MobileSidebarProps {
  className?: string
}

export const MobileSidebar = ({ className }: MobileSidebarProps) => {
  const { openMenu, setOpenMenu } = useUiContext()
  const location = useLocation()
  const navigate = useNavigate()
  const { theme } = useTheme()

  const url =
    theme === 'dark'
      ? 'https://app.cuatrocarnes.com/logo-cuatrocarnes-crema.svg'
      : 'https://app.cuatrocarnes.com/logo-cuatro-carnes.svg'

  const handleNavigate = (path: string) => {
    navigate(path)
    handleOnClose()
  }

  const handleOnClose = () => {
    setOpenMenu(false)
  }

  const items = [
    {
      key: '/stats',
      icon: <IoStatsChart size={20} />,
      label: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span>Estadísticas</span>
          <Tag
            color="default" // Usa el color por defecto
            style={{
              borderRadius: '', // Bordes redondeados completos
              padding: '0 25px', // Espacio horizontal dentro del tag
              fontSize: '12px', // Tamaño del texto
              marginLeft: 'auto', // Empuja el tag a la derecha
            }}
          >
            Proximamente
          </Tag>
        </div>
      ),
      onClick: () => handleNavigate('/stats'),
    },
    {
      key: '/',
      icon: <MdOutlineComputer size={20} />,
      label: 'Pedidos',
      onClick: () => handleNavigate('/'),
    },
    {
      key: '/products',
      icon: <BsBoxSeam size={20} />,
      label: 'Productos',
      onClick: () => handleNavigate('/products'),
    },
    {
      key: '/categories',
      icon: <BiCategory size={20} />,
      label: 'Categorías',
      onClick: () => handleNavigate('/categories'),
    },
    {
      key: '/options',
      icon: <TfiMenuAlt size={20} />,
      label: 'Opciones',
      onClick: () => handleNavigate('/options'),
    },
    {
      key: '/coupons',
      icon: <BsTicketPerforated size={20} />,
      label: 'Cupones',
      onClick: () => handleNavigate('/coupons'),
    },
    {
      key: '/users',
      icon: <RiUserSettingsLine size={20} />,
      label: 'Usuarios',
      onClick: () => handleNavigate('/users'),
    },
  ]

  return (
    <Drawer
      classNames={{ body: '!p-0' }}
      onClose={handleOnClose}
      closable={false}
      placement="left"
      open={openMenu}
    >
      <nav className={cn('h-full', className)}>
        <div className="px-6 py-3 flex">
          <img className="w-20" src={url} alt="Logo Cuatro carnes" />
        </div>
        <Menu
          mode="inline"
          style={{ background: 'none' }}
          items={items.map(({ icon, key, label, onClick }) => ({
            icon,
            key,
            label,
            onClick,
          }))}
          defaultSelectedKeys={[location.pathname]}
        />
      </nav>
    </Drawer>
  )
}
