export const FLEETS = [
  {
    value: 'yellow',
    label: 'Amarillo',
    color: '#fadb14',
    key: 1
  },
  {
    value: 'blue',
    label: 'Azul',
    color: '#1677ff',
    key: 2
  },
  {
    value: 'green',
    label: 'Verde',
    color: '#52c41a',
    key: 3
  },
  {
    value: 'default',
    label: 'Blanco',
    color: 'default',
    key: 4
  },
]