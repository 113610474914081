import { useEffect, useState } from "react";
import { Button, Drawer, Form, Input, Switch } from "antd";
import { toast } from "sonner";

import { useUiContext } from "../../context/ui";
import { createCategory, getCategoryById, updateCategory } from "../../api/category";
import { ICategory } from "../../interfaces/category";
import { useCategoriesContext } from "../../context/categories";

const { TextArea } = Input;
const { useForm } = Form;

export const CategoryDrawer = () => {
  const [form] = useForm()
  const [loading, setLoading] = useState(false)
  const { handleGetCategories } = useCategoriesContext()
  const { setShowCategoryDrawer, showCategoryDrawer } = useUiContext()
  const action = showCategoryDrawer.id ? 'Actualizar' : 'Crear'

  const handleSubmit = (values: ICategory) => {
    setLoading(true)
    if (!showCategoryDrawer.id) {
      createCategory(values)
        .then((response) => {
          toast.success('Categoria creada con exito!.')
          handleGetCategories()
          form.resetFields()
          handleCancel()
        })
        .catch((error) => {
          toast.error('Ops! Algo salio mal.')
          console.log(error)
        })
        .finally(() => setLoading(false))
    } else {
      updateCategory(showCategoryDrawer.id, values)
        .then((response) => {
          toast.success('Categoria actualizada con exito!.')
          handleGetCategories()
          form.resetFields()
          handleCancel()
        })
        .catch((error) => {
          console.log(error)
          toast.error('Ops! Algo salio mal.')
        })
        .finally(() => setLoading(false))
    }
  }

  const handleCancel = () => {
    setShowCategoryDrawer({ id: null, visible: false })
  }

  useEffect(() => {
    form.resetFields()
    if (!showCategoryDrawer.id) return
    getCategoryById(showCategoryDrawer.id)
      .then(({ data }) => {
        form.setFieldsValue(data)
      })
      .catch((error) => console.log(error))
      .finally()
  }, [showCategoryDrawer])


  return (
    <Drawer
      width={400}
      title="Nueva Categoría"
      placement="right"
      onClose={() => handleCancel()}
      open={showCategoryDrawer.visible}
      footer={
        <Button loading={loading} block type="primary" onClick={() => form.submit()}>
          {action}
        </Button>
      }
    >
      <Form
        layout='vertical'
        name="basic"
        form={form}
        initialValues={{
          price: 0
        }}
        onFinish={handleSubmit}
        autoComplete="off"
        requiredMark={false}
      >
        <Form.Item
          label="Nombre de la Categoría"
          name="name"
        >
          <Input placeholder='Escribe el nombre del producto' size='large' />
        </Form.Item>

        <Form.Item
          label="Estado"
          name="status"
        >
          <Switch />
        </Form.Item>

        <Form.Item
          label="Descripción"
          name="description"
        >
          <TextArea
            size='large'
            style={{ height: 120 }}
            placeholder='Escribe una descripción del producto'
          />
        </Form.Item>
      </Form>
    </Drawer >
  )
}
