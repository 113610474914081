import instance from ".";
import { IUser } from "../interfaces/user"

export type UserLogin = Pick<IUser, 'email' | 'password'>;
export type UserRegister = Pick<IUser, 'email' | 'username' | 'password'>;

export interface Query {
  limit?: number
  page?: number
  term?: string
  role?: string
  sort?: string;
  orderBy?: string;
}

export const Users = (q: Query) => {
  const params = new URLSearchParams(q as {})
  return instance.get('/auth/users?' + params.toString())
}

export const Login = (payload: UserLogin) => {
  return instance.post('/auth/login', payload)
}

export const Register = (payload: UserRegister) => {
  return instance.post('/auth/register', payload)
}

export const Update = (id: string, payload: IUser) => {
  return instance.patch(`/auth/user/${id}`, payload)
}

export const Remove = (id: string) => {
  return instance.delete(`/auth/user/${id}`)
}

export const Verify = () => {
  return instance.get('/auth/verify')
}
