import { MoonIcon, SunIcon } from 'lucide-react';
import { Avatar } from 'antd';
import { useTheme } from '../../context/theme';

export default function ThemeToggle() {
  const { theme, setTheme } = useTheme();

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return (
    <Avatar onClick={toggleTheme} className="cursor-pointer">
      <SunIcon className={`h-[1.2rem] w-[1.2rem] ${theme === 'light' ? 'rotate-0 scale-100' : 'rotate-90 scale-0'} transition-all`} />
      <MoonIcon className={`absolute top-0 h-[1.2rem] w-[1.2rem] ${theme === 'dark' ? 'rotate-0 scale-100' : 'rotate-90 scale-0'} transition-all`} />
    </Avatar>
  );
}
