import instance from "."

interface IItem {
  name: string
  price: string
  status?: boolean
}

export const getItem = (id: string, ItemId: string) => {
  return instance.get(`/items/${id}/${ItemId}`)
}

export const createItem = (id: string, payload: IItem) => {
  return instance.post(`/items/${id}`, payload)
}

export const updateItem = (id: string, ItemId: string, payload: IItem) => {
  return instance.patch(`/items/${id}/${ItemId}`, payload)
}

export const deleteItem = (id: string, ItemId: string,) => {
  return instance.delete(`/items/${id}/${ItemId}`)
}