import { useState } from 'react'
import { toast } from 'sonner'
import { Modal } from 'antd'

import { Remove } from '../../api/auth'
import { useUiContext } from '../../context/ui'
import { useUsersContext } from '../../context/users'

export const DeletUserModal = () => {
  const { showDeletuser, setShowDeletuser } = useUiContext()
  const { handleFilterChange, page, limit } = useUsersContext()
  const { user, visible } = showDeletuser
  const [loading, setLoading] = useState(false)

  const handleOk = () => {
    if (!user?._id) return
    setLoading(true)
    Remove(user._id)
      .then(({ data }) => {
        toast.success('¡Usuario eliminado con éxito!')
        handleFilterChange([["page", page], ["limit", limit]])
        handleCancel()
      })
      .catch((error) => {
        console.log(error)
        toast.error('¡Ups! Algo salió mal.')
      })
      .finally(() => setLoading(false))
  }

  const handleCancel = () => {
    setShowDeletuser({ visible: false, user: null })
  }

  return (
    <Modal
      title={`Eliminar al usuario ${user?.username}`}
      confirmLoading={loading}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Si, eliminar"
      cancelText="Cancelar"
    >
      <p className='mt-4'>¿Estas seguro que deseas eliminar este usuario?</p>
      <p className='mt-4 text-red-500'>Una vez eliminado no se podrá recuperar.</p>
    </Modal>
  )
}
