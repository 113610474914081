import { Route, Routes } from "react-router-dom"

import { LoginScreen } from "../screens/auth/login-screen"
import { DashboardRouter } from "./dashboard-router"
import { MainLayout } from "../components/layout"
import { PrivateRoute } from "./private-route"
import { PublicRoute } from "./public-routes"

export const AppRouter = () => {

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <PublicRoute>
            <LoginScreen />
          </PublicRoute>
        }
      />

      <Route
        path="/*"
        element={
          <PrivateRoute>
            <MainLayout>
              <DashboardRouter />
            </MainLayout>
          </PrivateRoute>
        }
      />
    </Routes>
  )
}