import { Checkbox, Modal, Space } from "antd";
import { useEffect, useState } from "react";

import { IOption } from "../../interfaces/options";
import { useOptionsContext } from "../../context/options";
import { useUiContext } from "../../context/ui";
import { getProductById, updateProductOption } from "../../api/product";
import { toast } from "sonner";

export const OptionsModal = () => {
  const [selectedOptions, setSelectedOptions] = useState<IOption[]>([]);
  const [loading, setLoading] = useState(false);
  const { showOptionsModal, setShowOptionsModal } = useUiContext();
  const { options } = useOptionsContext();

  const handleOk = () => {
    if (!showOptionsModal.id) return;
    setLoading(true);
    updateProductOption(showOptionsModal.id, selectedOptions)
      .then(({ data }) => {
        toast.success("¡Opciones actualizadas!");
        setShowOptionsModal({ id: null, visible: false });
      })
      .catch((error) => {
        console.log(error);
        toast.error("Oops! Ocurrio un error");
      })
      .finally(() => setLoading(false));
  };

  const handleCheckboxChange = (option: IOption, checked: boolean) => {
    if (checked) {
      setSelectedOptions((prevSelectedOptions) => [
        ...prevSelectedOptions,
        option,
      ]);
    } else {
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.filter((o) => o._id !== option._id)
      );
    }
  };

  const handleCancel = () => {
    setShowOptionsModal({ id: null, visible: false });
  };

  useEffect(() => {
    if (!showOptionsModal.id) return;

    getProductById(showOptionsModal.id).then(({ data }) => {
      setSelectedOptions(
        data.options.filter((option: IOption) => option.quantity > 0)
      );
    });
  }, [showOptionsModal]);

  return (
    <Modal
      open={showOptionsModal.visible}
      confirmLoading={loading}
      onCancel={handleCancel}
      title="Opciones"
      onOk={handleOk}
      okText="Guardar"
      cancelText="Cancelar"
    >
      <h3 className="mb-4 text-zinc-500">Tilde las opciones que quiere agregar al producto.</h3>
      <Space direction="vertical">
        {options.map((option: IOption) => (
          <Checkbox
            key={option._id}
            value={option}
            checked={selectedOptions.some((o) => o._id === option._id)}
            onChange={(e) => handleCheckboxChange(option, e.target.checked)}
          >
            {option.name}
          </Checkbox>
        ))}
      </Space>
    </Modal>
  );
};
