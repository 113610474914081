import { Form, Input, Modal, Select } from 'antd'
import 'react-datepicker/dist/react-datepicker.css'
import { useEffect, useState } from 'react'

import { STATUSES } from '../../constants/status'
import { useUiContext } from '../../context/ui'
import { CustomSelect } from '../custom-select'
import { updateOrdersById } from '../../api/orders'
import { toast } from 'sonner'
import { useOrderContext } from '../../context/order'

export const OrderModal = () => {
  const { showOrderModal, setShowOrderModal } = useUiContext()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const {hadleGetOrders, page} = useOrderContext()

  const onFinish = (values: any) => {
    if (!showOrderModal?.order?._id) return
    console.log(values)
    setLoading(true)
    updateOrdersById(showOrderModal?.order?._id, values)
      .then((res) => {
        console.log(res)
        toast.success('¡Orden actualizada con éxito!')
        hadleGetOrders({page});
        setShowOrderModal({ visible: false, order: null })
      })
      .catch((error) => {
        console.log(error)
        toast.error('¡Error al actualizar la orden!')
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (showOrderModal.visible && showOrderModal.order) {
      form.resetFields()
      form.setFieldsValue(showOrderModal.order)
    }
  }, [showOrderModal, form])

  return (
    <Modal
      title={`Pedido Nro. ${
        showOrderModal?.order?.uniqueID
          ? showOrderModal.order.uniqueID
          : '00000'
      }`}
      confirmLoading={loading}
      onOk={() => form.submit()}
      open={showOrderModal.visible}
      onCancel={() => setShowOrderModal({ visible: false, order: null })}
      okText="Actualizar"
      cancelText="Cancelar"
    >
      <Form
        onFinish={onFinish}
        layout="vertical"
        form={form}
        requiredMark={false}
        initialValues={{
          fleet: 'default',
          deadline: new Date()
        }}
      >
        <div className="grid md:grid-cols-2 gap-x-6">
          <Form.Item
            label="Estado"
            name="status"
            rules={[
              { required: true, message: 'Por favor, selecciona un estado.' },
            ]}
          >
            <Select
              className="w-full"
              options={STATUSES.map((status) => ({
                value: status.value,
                label: status.label,
                key: status.value,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Color"
            name="fleet"
            rules={[
              { required: true, message: 'Por favor, selecciona un color.' },
            ]}
          >
            <CustomSelect form={form} />
          </Form.Item>
          <Form.Item
            label="Fecha de entrega"
            name="deadline"
            rules={[
              {
                required: true,
                message: 'Por favor, selecciona una fecha de entrega.',
              },
            ]}
          >
            <Input type="date" />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}
