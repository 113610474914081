import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import updateLocale from 'dayjs/plugin/updateLocale'

dayjs.extend(updateLocale)
dayjs.locale('es')

export const CurrentTime: React.FC = () => {
  const [date, setDate] = useState(dayjs().format('dddd, D [de] MMMM'))
  const [time, setTime] = useState(dayjs().format('hh:mm'))

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(dayjs().format('dddd, D [de] MMMM'))
      setTime(dayjs().format('hh:mm'))
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="flex flex-col items-end mr-4">
      <span className="text-xs text-zinc-500">{date}</span>
      <span className="text-md font-semibold">{time}</span>
    </div>
  )
}
