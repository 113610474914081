import { Card } from "antd"

interface CardStatsProps {
  icon: JSX.Element
  title: string
  description: string
  count: number
}

export const CardStats = ({ count, description, icon, title }: CardStatsProps) => {
  return (
    <Card className="shadow-md">
      <div className="flex flex-row items-center justify-between space-y-0 pb-2">
        <div className="text-xl font-medium">
          {title}
        </div>
        {icon}
      </div>
      <div>
        <div className="text-2xl font-bold">{count}</div>
        <p className="text-xs text-muted-foreground">
          {description}
        </p>
      </div>
    </Card>
  )
}