import { Button, DatePicker, Input } from 'antd'
import { ArchiveX, Box, Package, PackageOpen } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { useTour } from '@reactour/tour'

import { OrderTable } from '../../components/tables/order/order-table'
import { CustomDropdown } from '../../components/custom-dropdown'
import { CardStats } from '../../components/card-stats'
import { useOrderContext } from '../../context/order'

const { RangePicker } = DatePicker
const { Search } = Input

export const HomeScreen = () => {
  const {
    stats,
    orders,
    loading,
    page,
    total,
    limit = 10,
    handleSearch,
    onPageChange,
    handleDateChange,
    onPageSizeChange,
  } = useOrderContext()
  const navigate = useNavigate()
  const { setIsOpen } = useTour()

  return (
    <div className="flex-1 space-y-4 p-4 md:p-8 pt-6">
      <div
        className="grid gap-4 md:grid-cols-2 lg:grid-cols-4"
        data-tut="fourth-step"
      >
        <CardStats
          count={stats?.today}
          description="Todos los pedidos del dia"
          icon={<Package />}
          title="Pedidos del dia"
        />
        <CardStats
          count={stats?.delivered}
          description="Pedidos entregados del día"
          icon={<PackageOpen />}
          title="Pedidos Entregados"
        />
        <CardStats
          count={stats?.pending}
          description="Pedidos pendientes"
          icon={<Box />}
          title="Pendientes"
        />
        <CardStats
          count={stats?.cancelled}
          description="Pedidos cancelados"
          icon={<ArchiveX />}
          title="Cancelados"
        />
      </div>
      <div className="flex flex-wrap gap-4 py-2">
        <div data-tut="fifth-step" className="flex-1 min-w-[200px]">
          <Search
            placeholder="Buscar..."
            onSearch={(value) => handleSearch(value)}
            allowClear
          />
        </div>

        <div data-tut="sixth-step" className="flex-1 min-w-[200px]">
          <RangePicker
            format={'DD/MM/YYYY'}
            style={{ width: '100%' }}
            onChange={(date) => handleDateChange(date)}
          />
        </div>
        <div className="flex-1 min-w-[200px]">
          <Button
            onClick={() => navigate('/order/reports')}
            data-tut="seven-step"
            className="w-full"
          >
            Descargar Reporte
          </Button>
        </div>
        <div className="flex">
          <div data-tut="eight-step">
            <CustomDropdown />
          </div>
        </div>
        <div className="flex-1 min-w-[200px]">
          <Button
            onClick={() => setIsOpen(true)}
            data-tut="start-tour-button"
            className="w-full"
          >
            Guía Interactiva
          </Button>
        </div>
      </div>

      <OrderTable
        isLoading={loading}
        data={orders}
        currentPage={page}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pageSize={limit}
        pages={Math.ceil(total / limit)}
      />
    </div>
  )
}
