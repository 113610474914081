import { Layout } from 'antd'
import { cn } from '../../libs/utils'
import ThemeToggle from './theme-toggle'
import { UserNavbar } from './user-navbar'
import { MobileSidebar } from './mobile-sidebar'
import { MenuIcon } from 'lucide-react'
import { useUiContext } from '../../context/ui'
import { useTheme } from '../../context/theme'
import { CurrentTime } from '../current-time'

export const Header = () => {
  const { setOpenMenu } = useUiContext()
  const { theme } = useTheme()
  const url =
    theme === 'dark'
      ? 'https://app.cuatrocarnes.com/logo-cuatrocarnes-crema.svg'
      : 'https://app.cuatrocarnes.com/logo-cuatro-carnes.svg'

  return (
    <header className="fixed top-0 left-0 right-0 supports-backdrop-blur:bg-background/60 border-b bg-background/95 backdrop-blur z-20">
      <nav className="h-14 flex items-center justify-between px-4">
        <div className="flex items-center gap-4">
          <div
            className="block cursor-pointer"
            data-tut="first-step"
            onClick={() => setOpenMenu(true)}
          >
            <MenuIcon />
          </div>
          <img className="w-20" src={url} alt="Logo Cuatro carnes" />
        </div>
        <div className="hidden md:block">
          <h3 className="text-sm text-center justify-center">
            <span className="font-semibold ">Dashboard</span> | Cuatro Carnes
          </h3>
        </div>
        <div className="flex items-center gap-2">
          <span className="hidden md:block">
            <CurrentTime />
          </span>
          <UserNavbar />
          <div data-tut="third-step">
            <ThemeToggle />
          </div>
        </div>
      </nav>
    </header>
  )
}
