import { useEffect, useState } from 'react';
import { Dropdown, MenuProps } from 'antd';
import { TfiReload } from 'react-icons/tfi';
import { useOrderContext } from '../context/order';

const { Button: DropdownButton } = Dropdown;

export const CustomDropdown = () => {
  const { loading, hadleGetOrders } = useOrderContext()
  const [intervalTime, setIntervalTime] = useState<number | null>(null);
  const [timeLeft, setTimeLeft] = useState<number | null>(null);

  const onMenuClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case '1':
        setIntervalTime(null);
        setTimeLeft(null);
        break;
      case '2':
        setIntervalTime(30000);
        setTimeLeft(30000);
        break;
      case '3':
        setIntervalTime(60000);
        setTimeLeft(60000);
        break;
      case '4':
        setIntervalTime(120000);
        setTimeLeft(120000);
        break;
      default:
        setIntervalTime(null);
        setTimeLeft(null);
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (intervalTime) {
      intervalId = setInterval(() => {
        hadleGetOrders()
        setTimeLeft(intervalTime);
      }, intervalTime);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalTime]);

  useEffect(() => {
    if (timeLeft === null || timeLeft <= 0) return;

    const countdownId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft && prevTimeLeft > 0) {
          return prevTimeLeft - 1000;
        }
        return null;
      });
    }, 1000);

    return () => {
      clearInterval(countdownId);
    };
  }, [timeLeft]);

  const items = [
    {
      key: '1',
      label: 'Ahora no',
    },
    {
      key: '2',
      label: '30s para actualizar',
    },
    {
      key: '3',
      label: '60s para actualizar',
    },
    {
      key: '4',
      label: '120s para actualizar',
    },
  ];

  return (
    <DropdownButton
      onClick={() => hadleGetOrders()}
      menu={{ items, onClick: onMenuClick }}
      loading={loading}
    >
      {loading ? <></> : (
        loading || timeLeft === null ? (
          <TfiReload />
        ) : (
          `${Math.ceil(timeLeft / 1000)}s`
        )
      )}
    </DropdownButton>
  );
};