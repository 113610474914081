import { Modal } from 'antd'
import { toast } from 'sonner'
import { useState } from 'react'

import { deleteProduct } from '../../api/product'
import { useUiContext } from '../../context/ui'
import { useProductContext } from '../../context/product'

export const DeleteProductModal = () => {
  const { fetchProducts, limit, page } = useProductContext()
  const { showDeletModal, setShowDeletModal } = useUiContext()
  const [loading, setLoading] = useState(false)

  const handleOk = () => {
    if (!showDeletModal.id) return
    setLoading(true)
    deleteProduct(showDeletModal.id)
      .then(({ data }) => {
        toast.success('¡Producto eliminado con éxito!')
        fetchProducts({ limit, page, category: undefined })
        setShowDeletModal({ visible: false, id: null })
      })
      .catch((error) => {
        console.log(error)
        toast.error('¡Ups! Algo salio mal.')
      })
      .finally(() => setLoading(false))
  }

  const handleCancel = () => {
    setShowDeletModal({ visible: false, id: null })
  }

  return (
    <Modal
      title="Eliminar Producto"
      confirmLoading={loading}
      open={showDeletModal.visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Si, eliminar"
      cancelText="Cancelar"
    >
      <p className="mt-4">¿Estás seguro que deseas eliminar este producto?</p>
      <p className="mt-4 text-red-500">
        Una vez eliminado no se podrá recuperar.
      </p>
    </Modal>
  )
}
