export enum ECuponDiscount {
  PERCENT = 'PERCENT',
  FIXED = 'FIXED'
}

export interface ICoupon {
  name: string;
  discount: number;
  description: string;
  user_id: string;
  is_active: boolean;
  min_amount: number;
  cap_amount: number;
  discount_value: number;
  discount_type: ECuponDiscount;
}