import { Card, Col, Row, Statistic, Button, Space, List } from 'antd'
import { useState } from 'react'
import { Line } from '@ant-design/plots'
import {
  DollarOutlined,
  UserOutlined,
  SolutionOutlined,
  CrownOutlined,
  GlobalOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons'
import { IoStatsChart } from 'react-icons/io5'

const getOrderData = (filter: 'month' | 'week' | 'day') => {
  switch (filter) {
    case 'month':
      return [
        { date: '2024-08-01', value: 50 },
        { date: '2024-08-05', value: 80 },
        { date: '2024-08-10', value: 120 },
        { date: '2024-08-15', value: 200 },
        { date: '2024-08-20', value: 150 },
      ]
    case 'week':
      return [
        { date: '2024-08-15', value: 30 },
        { date: '2024-08-16', value: 40 },
        { date: '2024-08-17', value: 60 },
        { date: '2024-08-18', value: 70 },
        { date: '2024-08-19', value: 90 },
      ]
    case 'day':
      return [
        { date: '2024-08-21', value: 10 },
        { date: '2024-08-22', value: 20 },
        { date: '2024-08-23', value: 30 },
        { date: '2024-08-24', value: 40 },
        { date: '2024-08-25', value: 50 },
      ]
    default:
      return []
  }
}

const topUsers = [
  { name: 'Usuario 1', points: 500 },
  { name: 'Usuario 2', points: 450 },
  { name: 'Usuario 3', points: 400 },
  { name: 'Usuario 4', points: 350 },
  { name: 'Usuario 5', points: 300 },
  { name: 'Usuario 6', points: 250 },
  { name: 'Usuario 7', points: 200 },
  { name: 'Usuario 8', points: 150 },
  { name: 'Usuario 9', points: 100 },
  { name: 'Usuario 10', points: 50 },
  { name: 'Usuario 11', points: 10 },
]

const topZones = [
  { zone: 'Zona Norte', orders: 120 },
  { zone: 'Zona Sur', orders: 90 },
  { zone: 'Zona Este', orders: 70 },
  { zone: 'Zona Oeste', orders: 50 },
  { zone: 'Capital Federal', orders: 30 },
]

export const StatsScreen = () => {
  const [filter, setFilter] = useState<'month' | 'week' | 'day'>('month')

  const linePlotConfig = {
    data: getOrderData(filter),
    xField: 'date',
    yField: 'value',
    smooth: true,
    point: {
      size: 5,
      shape: 'circle',
    },
    xAxis: {
      type: 'dateTime',
      tickCount: 5,
      label: {
        formatter: (v: string) => new Date(v).toLocaleDateString(),
      },
    },
    yAxis: {
      label: {
        formatter: (v: number) => `${v}`,
      },
    },
  }

  const barPlotConfig = {
    data: topZones,
    xField: 'orders',
    yField: 'zone',
    seriesField: 'zone',
    legend: false,
    label: {
      position: 'right',
      layout: 'horizontal',
    },
    xAxis: {
      label: {
        formatter: (v: number) => `${v} pedidos`,
      },
    },
    yAxis: {
      title: {
        text: 'Zona',
      },
    },
  }

  return (
    <div className="p-4 md:p-8">
      <div className="flex items-center justify-start space-x-3 mb-4">
        <IoStatsChart size={24} />
        <h1 className="font-semibold text-2xl">Estadísticas</h1>
      </div>
      <Space className="mb-4">
        <Button
          type={filter === 'month' ? 'primary' : 'default'}
          onClick={() => setFilter('month')}
        >
          Mes
        </Button>
        <Button
          type={filter === 'week' ? 'primary' : 'default'}
          onClick={() => setFilter('week')}
        >
          Semana
        </Button>
        <Button
          type={filter === 'day' ? 'primary' : 'default'}
          onClick={() => setFilter('day')}
        >
          Día
        </Button>
      </Space>
      <Row gutter={16}>
        <Col span={8}>
          <Card
            title={
              <span>
                <DollarOutlined className="mr-2" /> Total Ventas
              </span>
            }
            bordered={false}
          >
            <Statistic value={1128} suffix="Ventas" valueStyle={{}} />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title={
              <span>
                <UserOutlined className="mr-2" /> Usuarios Activos
              </span>
            }
            bordered={false}
          >
            <Statistic value={256} suffix="Usuarios" valueStyle={{}} />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title={
              <span>
                <SolutionOutlined className="mr-2" /> Tickets Resueltos
              </span>
            }
            bordered={false}
          >
            <Statistic value={35} suffix="Tickets" valueStyle={{}} />
          </Card>
        </Col>
      </Row>
      <Row className="mt-6" gutter={16}>
        <Col span={16}>
          <Card
            title={
              <span>
                <SolutionOutlined className="mr-2" /> Pedidos a lo largo del
                tiempo
              </span>
            }
            bordered={false}
          >
            <Line {...linePlotConfig} />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title={
              <span>
                <CrownOutlined className="mr-2" /> Usuarios con más puntos
              </span>
            }
            bordered={false}
          >
            <List
              dataSource={topUsers.slice(0, 10)}
              renderItem={(item) => (
                <List.Item>
                  <div className="flex justify-between w-full">
                    <span>{item.name}</span>
                    <span>{item.points} puntos</span>
                  </div>
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}
