import {
  Button,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Spin,
  Tooltip,
  Upload,
  UploadProps,
} from "antd";
import { RcFile, UploadChangeParam, UploadFile } from "antd/es/upload";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { useEffect, useState } from "react";
import { UploadCloud } from "lucide-react";
import { toast } from "sonner";
import axios from "axios";

import { createProduct, updateProduct } from "../../api/product";
import { useProductContext } from "../../context/product";
import { ICategory } from "../../interfaces/category";
import { IProduct } from "../../interfaces/product";
import { useUiContext } from "../../context/ui";
import { useCategoriesContext } from "../../context/categories";

const { TextArea } = Input;
const { useForm } = Form;

export const ProductDrawer = () => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [loadingImage, setLoadingImage] = useState(false);
  const [isOffer, setIsOffer] = useState(
    form.getFieldValue("is_offer") || false
  );
  const [isOfferQuantity, setIsOfferQuantity] = useState(
    form.getFieldValue("is_offer_quantity") || false
  );
  const { categories } = useCategoriesContext();
  const { fetchProducts, page, limit } = useProductContext();
  const {
    showProductDrawer: { visible, product },
    setShowProductDrawer,
  } = useUiContext();
  const message = product ? "¡Producto actualizado con éxito!." : "¡Producto creado con éxito! Recuerde activarlo.";
  const title = product ? "Modificar Producto" : "Agregar Producto";
  const action = product ? "Actualizar" : "Cargar";

  const handleSubmit = (values: IProduct) => {
    setLoading(true);
    if (!product) {
      createProduct({
        ...values,
        price: Number(values.price),
        image: imageUrl!,
      })
        .then(() => {
          form.resetFields();
          toast.success(message);
          fetchProducts({ limit, page, category: undefined });
          setShowProductDrawer({ visible: false, product: undefined });
        })
        .catch((error) => {
          console.log(error);
          toast.error("Oops! Algo salio mal.");
        })
        .finally(() => setLoading(false));
    } else {
      updateProduct(product._id, {
        ...values,
        image: imageUrl!,
      })
        .then(() => {
          form.resetFields();
          toast.success(message);
          fetchProducts({ limit, page, category: undefined });
          setShowProductDrawer({ visible: false, product: undefined });
        })
        .catch((error) => {
          console.log(error);
          toast.error("Oops! Algo salio mal.");
        })
        .finally(() => setLoading(false));
    }
  };

  const MAX_FILE_SIZE_MB = 3;

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      // Check if the file is in PNG format
      if (info.file.type !== "image/png") {
        toast.warning("Solo se permiten archivos PNG.");
        return;
      }
      // // Check if the file size exceeds the limit
      if (info.file.size! / 1024 / 1024 > MAX_FILE_SIZE_MB) {
        toast.warning(
          `El archivo es demasiado grande. El tamaño máximo permitido es ${MAX_FILE_SIZE_MB} MB.`
        );
        return;
      }
      setLoadingImage(true);
      const formData = new FormData();
      formData.append("file", info.file.originFileObj as RcFile);
      formData.append("upload_preset", "prueba");

      axios
        .post(
          "https://api.cloudinary.com/v1_1/alexispavon010/image/upload",
          formData
        )
        .then((response) => {
          setLoadingImage(false);
          setImageUrl(response.data.secure_url);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    setImageUrl(undefined);
    form.resetFields();
    if (!product) return;
    setImageUrl(product.image);
    form.setFieldsValue(product);
    setIsOffer(form.getFieldValue("is_offer") || false);
    setIsOfferQuantity(form.getFieldValue("is_offer_quantity") || false);
  }, [product, form]);

  return (
    <Drawer
      width={500}
      title={title}
      placement="right"
      onClose={() =>
        setShowProductDrawer({ visible: false, product: undefined })
      }
      open={visible}
      footer={
        <Button loading={loading} block onClick={() => form.submit()}>
          {action}
        </Button>
      }
    >
      <div>
        <Upload
          name="file"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          onChange={handleChange}
        >
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="avatar"
              style={{
                width: "100px",
                height: "100px",
                objectFit: "cover",
                borderRadius: "8px",
              }}
            />
          ) : (
            <div className="flex flex-col items-center justify-center">
              {loadingImage ? <Spin /> : <UploadCloud size={24} />}
              <div style={{ marginTop: 8 }}>Cargar</div>
            </div>
          )}
        </Upload>
      </div>
      <Form
        form={form}
        layout="vertical"
        name="basic"
        onFinish={handleSubmit}
        autoComplete="off"
        requiredMark={false}
        initialValues={{
          is_new: false,
          is_offer: false,
          stock: "QUANTITY",
        }}
      >
        <Form.Item
          label="Nombre del Producto"
          name="title"
          className="mt-4"
          rules={[
            { required: true, message: "El nombre del producto es requerido." },
          ]}
        >
          <Input placeholder="Escribe el nombre del producto" size="large" />
        </Form.Item>

        <Form.Item label="Codigo de producto" name="product_code">
          <InputNumber
            style={{ width: "100%" }}
            size="large"
            addonBefore="#"
            placeholder="000000"
          />
        </Form.Item>

        <Form.Item label="Stock" name="stock">
          <Radio.Group defaultValue={"QUANTITY"}>
            <Radio value="QUANTITY">Cantidad</Radio>
            <Radio value="KILOGRAM">Kg.</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="¿Es Nuevo?" name="is_new">
          <Radio.Group>
            <Radio value={false}>No</Radio>
            <Radio value={true}>Si</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="¿Está en oferta?" name="is_offer">
          <Radio.Group onChange={(e) => setIsOffer(e.target.value)}>
            <Radio value={false}>No</Radio>
            <Radio value={true}>Si</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="¿Quieres destacarlo?" name="is_highlighted">
          <Radio.Group>
            <Radio value={false}>No</Radio>
            <Radio value={true}>Si</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Precio regular"
          name="price"
          rules={[{ required: true, message: "El precio es requerido." }]}
        >
          <InputNumber style={{ width: "100%" }} size="large" addonBefore="$" />
        </Form.Item>

        <Form.Item
          label="Precio x kg."
          name="price_kg"
          rules={[{ required: true, message: "El precio es requerido." }]}
        >
          <InputNumber style={{ width: "100%" }} size="large" addonBefore="$" />
        </Form.Item>

        <Form.Item
          label="Peso"
          name="weight"
          extra={
            <h3 className="mt-2 text-zinc-500 text-sm italic">
              Si el producto es por kg. escriba 1 kg.
            </h3>
          }
        >
          <InputNumber
            style={{ width: "100%" }}
            size="large"
            addonBefore="Kg"
          />
        </Form.Item>

        {/* <Form.Item
          label="Cantidad"
          name="quantity"
          rules={[{ required: true, message: 'El precio es requerido.' }]}
        >
          <InputNumber style={{ width: '100%' }} size='large' addonBefore="Kg" />
        </Form.Item> */}

        {isOffer && (
          <Form.Item
            shouldUpdate
            label={
              <div className="flex justify-between items-center space-x-2">
                <span>Precio Oferta</span>
                <Tooltip title="Cuando un producto está marcado en oferta.">
                  <AiOutlineQuestionCircle size="16" />
                </Tooltip>
              </div>
            }
            name="offert_price"
            rules={[
              { required: true, message: "El precio oferta es requerido." },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              size="large"
              addonBefore="$"
            />
          </Form.Item>
        )}

        <Form.Item label="¿Oferta por cantidad?" name="is_offer_quantity">
          <Radio.Group onChange={(e) => setIsOfferQuantity(e.target.value)}>
            <Radio value={false}>No</Radio>
            <Radio value={true}>Si</Radio>
          </Radio.Group>
        </Form.Item>

        {isOfferQuantity && (
          <>
            <Form.Item
              label="Cantidad / Kilogramos"
              name="offer_quantity"
              rules={[{ required: true, message: "La cantidad es requerida." }]}
            >
              <InputNumber style={{ width: "100%" }} size="large" />
            </Form.Item>
            <Form.Item
              label="Precio por cantidad"
              name="offer_quantity_price"
              rules={[
                {
                  required: true,
                  message: "El precio por cantidad es requerido.",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                size="large"
                addonBefore="$"
              />
            </Form.Item>
          </>
        )}

        <Form.Item
          label="Categoría"
          name="category"
          rules={[{ required: true, message: "La categoría es requerida" }]}
          extra={
            <h3 className="mt-2 text-zinc-500 text-sm italic">
              Para elevar sus productos a la sección principal, debe asignarle "Ofertas semanales" como categoría.
            </h3>
          }
        >
          <Select
            placeholder="Seleccione una categoría"
            size="large"
            options={categories.map((item: ICategory) => ({
              value: item.name,
              label: item.name,
            }))}
          />
        </Form.Item>

        <Form.Item
          label="Descripción"
          name="description"
          rules={[{ required: true, message: "La descripcíon es requerida." }]}
        >
          <TextArea
            size="large"
            style={{ height: 120 }}
            placeholder="Escribe una descripción del producto"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};
