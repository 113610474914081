import instance from "."
import { IOption } from "../interfaces/options";
import { IProduct } from "../interfaces/product";


export interface Pagination {
  limit?: number;
  page?: number;
  status?: boolean;
  term?: string
  category?: string
}


type CreateProduct = Pick<IProduct, 'category' | 'description' | 'image' | 'price'>;
type UpdateProduct = Pick<IProduct, 'category' | 'description' | 'image' | 'price'>;
type ActivateProduct = Pick<IProduct, 'status'>;



export const getProducts = (query: any) => {
  const params = new URLSearchParams(query as {})
  return instance.get(`products?${params?.toString()}`)
}

export const getProductById = (id: string) => {
  return instance.get(`products/${id}`)
}

export const createProduct = (payload: CreateProduct) => {
  return instance.post('products', payload)
}

export const updateProduct = (id: string, payload: UpdateProduct) => {
  return instance.patch(`products/${id}`, payload)
}

export const activateProduct = (id: string, payload: ActivateProduct) => {
  return instance.put(`products/${id}`, payload)
}

export const deleteProduct = (id: string) => {
  return instance.delete(`products/${id}`)
}

export const updateProductOption = (id: string, payload: IOption[]) => {
  return instance.post(`products/${id}`, payload)
}