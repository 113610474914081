import {
  Card,
  Row,
  Col,
  Space,
  Button,
  Table,
  Dropdown,
  MenuProps,
  Input,
} from 'antd'
import { ColumnsType } from 'antd/es/table'
import { BiPencil } from 'react-icons/bi'
import { BsTrash } from 'react-icons/bs'
import { useOptionsContext } from '../../../context/options'
import { OptionDrawer } from '../../../components/drawer/option-drawer'
import { useUiContext } from '../../../context/ui'
import { DeleteOptionModal } from '../../../components/modals/delet-options'
import { TfiMenuAlt } from 'react-icons/tfi'

const { Search } = Input

export const OptionScreen = () => {
  const { options } = useOptionsContext()
  const { setShowOptionsDrawer, setShowDeletOption } = useUiContext()

  const getMenuItems = (record: any | undefined): MenuProps['items'] => [
    {
      key: '1',
      icon: <BiPencil size={14} />,
      label: 'Modificar',
      onClick: () => setShowOptionsDrawer({ visible: true, id: record?._id! }),
    },
    {
      key: '5',
      icon: <BsTrash size={14} />,
      label: 'Eliminar',
      onClick: () => setShowDeletOption({ visible: true, id: record?._id! }),
    },
  ]

  const columns: ColumnsType<any> = [
    {
      title: 'Grupo de Opción',
      dataIndex: 'name',
      key: 'name',
    },
    {
      align: 'center',
      width: 60,
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <Dropdown
          menu={{ items: getMenuItems(record) }}
          placement="bottomRight"
          trigger={['click']}
        >
          <Button>Acciones</Button>
        </Dropdown>
      ),
    },
  ]

  return (
    <div className="flex-1 space-y-4 p-4 md:p-8 pt-6">
      <div className="flex items-center justify-start space-x-3">
        <TfiMenuAlt size={24} />
        <h1 className="font-semibold text-2xl">Opciones</h1>
      </div>
      <Card>
        <Row gutter={[0, 12]}>
          <Col xs={24} md={6} lg={6}>
            En esta sección puedes crear y gestionar opciones para
            personalizar tus productos aun más.
          </Col>
          <Col flex={1}></Col>
          <Col>
            <Space wrap>
              <Button
                onClick={() =>
                  setShowOptionsDrawer({ id: null, visible: true })
                }
              >
                Crear Opción
              </Button>
            </Space>
          </Col>
        </Row>
      </Card>
      <Table
        loading={false}
        columns={columns}
        scroll={{ x: 1000 }}
        dataSource={options}
        rowKey="_id"
      />
      <DeleteOptionModal />
      <OptionDrawer />
    </div>
  )
}
