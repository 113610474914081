import { toast } from "sonner";
import { useState } from "react";
import { Modal, message } from "antd";

import { useUiContext } from "../../context/ui";
import { deleteOption } from "../../api/options";
import { useOptionsContext } from "../../context/options";


export const DeleteOptionModal = () => {
  const { handleGetOptions } = useOptionsContext()
  const { showDeletOption, setShowDeletOption } = useUiContext()
  const [loading, setLoading] = useState(false)

  const handleOk = () => {
    if (!showDeletOption.id) return
    setLoading(true)
    deleteOption(showDeletOption.id)
      .then(({ data }) => {
        toast.success('Opción Eliminada')
        handleGetOptions()
        handleCancel()
      })
      .catch((error) => {
        toast.error('Opss Ocurrio un Error.')
        console.log(error)
      })
      .finally(() => setLoading(false))
  };

  const handleCancel = () => {
    setShowDeletOption({ visible: false, id: null });
  };

  return (
    <Modal
      title="Eliminar Opción"
      confirmLoading={loading}
      open={showDeletOption.visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <p>Estas seguro que desea eliminar esta opcion?.
        Una vez eliminado no se podra recuperar.</p>
    </Modal>
  )
}