import { RcFile, UploadFile } from "antd/es/upload"

import instance from "."

interface Payload {
  email: string
  uniqueID: string
  files: UploadFile[]
}

export const sendInvoice = ({ email, files, uniqueID }: Payload) => {

  const formData = new FormData();
  formData.append('file', files[0] as RcFile);
  formData.append('email', email);
  formData.append('uniqueID', uniqueID);

  return instance.post('/email/invoice', formData)
}