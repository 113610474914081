import { createContext, useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Query, Users } from '../api/auth'

interface UserProviderProps {
  children: JSX.Element | JSX.Element[]
}

const Context = createContext<undefined | any>(undefined)

export const useUsersContext = () => {
  const context = useContext(Context)
  if (!context) {
    throw new Error('Users context debe ser utilizado dentro de un provider.')
  }
  return context
}

export const UsersProvider = ({ children }: UserProviderProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1)
  const [limit, setLimit] = useState(Number(searchParams.get('limit')) || 10)
  const [total, setTotal] = useState(0)

  const handleFilterChange = (q?: [string, string][]) => {
    setSearchParams((params) => {
      q?.forEach(([key, value]) => {
        if (!value) {
          params.delete(key)
        } else {
          params.set(key, value)
        }
      })
      return params
    })
  }

  const fetchUsers = (query: Query) => {
    setLoading(true)
    console.log(query)
    Users(query)
      .then(({ data }) => {
        setUsers(data.results)
        console.log(data.metadata.page)
        setPage(data.metadata.page)
        setLimit(data.metadata.limit)
        setTotal(data.metadata.total)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const currentPage = searchParams.get('page') || '1'
    const currentLimit = searchParams.get('limit') || '10'
    const currentTerm = searchParams.get('term') || ''
    const currentRole = searchParams.get('role') || ''
    const currentOrder = searchParams.get('orderBy') || 'createdAt' as "score" | "createdAt"
    const currentSort = searchParams.get('sort') || 'asc' as "asc" | "desc"

    fetchUsers({
      page: Number(currentPage),
      limit: Number(currentLimit),
      term: currentTerm,
      role: currentRole,
      sort: currentSort,
      orderBy: currentOrder
    })
  }, [searchParams])

  return (
    <Context.Provider value={{
      users,
      loading,
      total,
      page,
      limit,
      handleFilterChange
    }}>
      {children}
    </Context.Provider>
  )
}