import React from 'react';
import { AiOutlineLock, AiOutlineMenu, AiOutlineUnlock } from 'react-icons/ai'
import { Button, Dropdown, MenuProps, Tag } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import { MdOutlineComputer } from 'react-icons/md'
import { BiPencil } from 'react-icons/bi'
import { Ellipsis } from 'lucide-react'
import { toast } from 'sonner'

import { useProductContext } from '../../../context/product'
import { IProduct } from '../../../interfaces/product'
import { activateProduct } from '../../../api/product'
import { useUiContext } from '../../../context/ui'

interface ProductTableProps {
  data: IProduct[]
  isLoading: boolean
  pages?: number
  currentPage?: number
  onPageChange?: any
  onPageSizeChange?: any
  pageSize?: number
}

export const ProductTable = ({
  data = [],
  isLoading,
  pages = 0,
  currentPage = 0,
  onPageChange,
  onPageSizeChange,
  pageSize = 0,
}: ProductTableProps) => {
  const { setShowProductDrawer, setShowOptionsModal, setShowDeletModal } =
    useUiContext()
  const { fetchProducts, limit, page } = useProductContext()

  const handleActivateProduct = (product: IProduct) => {
    const status = product.status ? false : true
    activateProduct(product._id, { status })
      .then(({ data }) => {
        fetchProducts({ limit, page, category: undefined })
        toast.success('¡Producto actualizado con éxito!')
      })
      .catch((error) => {
        toast.error('¡Ups! Algo salio mal.')
        console.log(error)
      })
  }

  const getMenuItems = (record: IProduct | undefined): MenuProps['items'] => [
    {
      key: '1',
      icon: <BiPencil size={14} />,
      label: 'Modificar',
      onClick: () => setShowProductDrawer({ visible: true, product: record }),
    },
    {
      key: '3',
      icon: <AiOutlineMenu size={14} />,
      label: 'Opciones',
      onClick: () => setShowOptionsModal({ visible: true, id: record?._id! }),
    },
    {
      key: '4',
      icon: record?.status ? (
        <AiOutlineLock size={14} />
      ) : (
        <AiOutlineUnlock size={14} />
      ),
      label: record?.status ? 'Desactivar' : 'Activar',
      onClick: () => handleActivateProduct(record!),
    },
    {
      key: '5',
      icon: <MdOutlineComputer size={14} />,
      label: 'Eliminar',
      onClick: () => setShowDeletModal({ visible: true, id: record?._id! }),
    },
  ]

  const columns: ColumnsType<any> = [
    {
      title: 'Código',
      dataIndex: 'product_code',
      key: 'product_code',
      align: 'left',
      render: (product_code) => <span>#{product_code}</span>,
    },
    {
      title: 'Imagen',
      dataIndex: 'image',
      key: 'image',
      render: (url) => (
        <img
          style={{ width: '40px', height: '36px', objectFit: 'cover' }}
          src={url}
          alt="Imagen del producto"
        />
      ),
    },
    {
      title: 'Nombre',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Categoría',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Tipo',
      dataIndex: 'stock',
      key: 'stock',
      align: 'center',
      render: (type) => {
        switch (type) {
          case 'QUANTITY':
            return 'Un.'
          case 'KILOGRAM':
            return 'Kg.'
        }
      },
    },
    {
      align: 'center',
      title: 'Precio',
      dataIndex: 'price',
      key: 'price',
      render: (total) => <div>${total}</div>,
    },
    {
      align: 'center',
      title: 'Precio Oferta',
      dataIndex: 'offert_price',
      key: 'offert_price',
      render: (total) => <div>${total ? total : 0}</div>,
    },
    {
      align: 'center',
      title: 'Oferta Cantidad',
      dataIndex: 'is_offer_quantity',
      key: 'is_offer_quantity',
      render: (isOfferQuantity) => (isOfferQuantity ? 'Si' : 'No'),
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (status) =>
        status ? (
          <Tag color="green">Activo</Tag>
        ) : (
          <Tag color="magenta">Inactivo</Tag>
        ),
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      render: (_, record) => (
        <Dropdown
          menu={{ items: getMenuItems(record) }}
          placement="bottomRight"
          trigger={['click']}
        >
          <Button type="dashed" icon={<Ellipsis />} />
        </Dropdown>
      ),
    },
  ]

  return (
    <Table
      loading={isLoading}
      scroll={{ x: 1000 }}
      columns={columns}
      dataSource={data}
      rowKey="_id"
      pagination={{
        className: 'section-not-print px-4',
        rootClassName: '',
        locale: {
          items_per_page: 'x pág.',
        },
        total: pageSize * pages,
        current: currentPage,
        pageSize: pageSize,
        onChange: onPageChange,
        onShowSizeChange: onPageSizeChange,
        showSizeChanger: true,
      }}
    />
  )
}
