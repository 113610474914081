import instance from "."
import { IOption } from "../interfaces/options";


export interface Pagination {
  limit?: number;
  page?: number;
  status?: boolean;
  term?: string
  category?: string
}


type CreateOption = IOption;
type UpdateOption = IOption;


export const getOptions = (query: any) => {
  const params = new URLSearchParams(query as {})
  return instance.get(`options?${params?.toString()}`)
}

export const getOptionById = (id: string) => {
  return instance.get(`options/${id}`)
}

export const createOption = (payload: CreateOption) => {
  return instance.post('options', payload)
}

export const updateOption = (id: string, payload: UpdateOption) => {
  return instance.patch(`options/${id}`, payload)
}

export const deleteOption = (id: string) => {
  return instance.delete(`options/${id}`)
}