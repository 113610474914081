import instance from "."
import { ICategory } from "../interfaces/category"

export const getCategories = (query?: string | string[][]) => {
  const params = new URLSearchParams(query)
  return instance.get(`/categories?${params.toString()}`)
}

export const createCategory = (payload: ICategory) => {
  return instance.post('/categories', payload)
}

export const getCategoryById = (id: string) => {
  return instance.get(`/categories/${id}`)
}

export const updateCategory = (id: string, payload: ICategory) => {
  return instance.patch(`/categories/${id}`, payload)
}

export const deleteCategory = (id: string,) => {
  return instance.delete(`/categories/${id}`)
}