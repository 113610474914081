import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { SWRConfig } from 'swr';

import { AppRouter } from './router/app-router';
import { ThemeProvider } from './context/theme';
import { AuthProvider } from './context/auth';
import instance from './api';
import './styles/global.css'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <AuthProvider>
    <BrowserRouter>
      <ThemeProvider>
        <SWRConfig
          value={{
            fetcher: async (url) => instance.get(url).then((res) => res.data)
          }}
        >
          <AppRouter />
        </SWRConfig>
      </ThemeProvider>
    </BrowserRouter>
  </ AuthProvider>
);