import instance from "."
import { IOrder } from "../interfaces/order";

export interface Pagination {
  limit?: number;
  page?: number;
  status?: string;
  start_date?: string;
  end_date?: string;
  term?: string
}

type UpadateOrder = Pick<IOrder, 'status' | 'fleet' | 'deadline'>;

export const getOrders = (query?: Pagination) => {
  const params = new URLSearchParams(query as {})
  return instance.get(`/orders?${params.toString()}`)
}

export const getOrdersById = (id: string) => {
  return instance.get(`/orders/${id}`)
}

export const updateOrdersById = (id: string, payload: UpadateOrder) => {
  return instance.patch(`/orders/${id}`, payload)
}