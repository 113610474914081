import { Card, Select, Input, Button, Form, FormInstance } from 'antd'
import { useLocation } from 'react-router-dom'
import { useState } from 'react'
import { toast } from 'sonner'

import { useOrderContext } from '../../../context/order'
import { updateOrdersById } from '../../../api/orders'
import { OPERATOR } from '../../../constants/operator'
import { STATUSES } from '../../../constants/status'
import { CustomSelect } from '../../custom-select'

export const CardForm = ({
  form,
  id,
  onUpdate,
}: {
  form: FormInstance<any>
  id: string
  onUpdate: () => void
}) => {
  const [loading, setLoading] = useState(false)

  const onSubmit = async (data: any) => {
    setLoading(true)
    updateOrdersById(id, data)
      .then(() => {
        toast.success('¡La orden se actualizó correctamente!')
        onUpdate() // Call the callback to notify the parent component
      })
      .catch((error) => {
        console.log(error)
        toast.error('¡Ups! Algo salió mal.')
      })
      .finally(() => setLoading(false))
  }

  return (
    <Card className="section-not-print">
      <Form
        requiredMark={false}
        onFinish={onSubmit}
        layout="vertical"
        form={form}
      >
        <div className="md:grid md:grid-cols-3 gap-x-8">
          <Form.Item label="Estado" name="status">
            <Select
              options={STATUSES.map((status) => ({
                value: status.value,
                label: status.label,
                key: status.value,
              }))}
            />
          </Form.Item>
          <Form.Item label="Color" name="fleet">
            <CustomSelect form={form} />
          </Form.Item>
          <Form.Item label="Fecha de entrega" name="deadline">
            <Input type="date" />
          </Form.Item>
          <Form.Item label="Tipo de canasto" name="basket_type">
            <Select placeholder="Seleccione el canasto" options={OPERATOR} />
          </Form.Item>
          <Form.Item label="Numero de operario" name="operator_number">
            <Input placeholder="000000" type="text" />
          </Form.Item>
        </div>
        <Form.Item>
          <Button loading={loading} type="default" htmlType="submit">
            Actualizar
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}
